import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UserService } from './../../shared/user.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {HttpClient, HttpHeaders} from "@angular/common/http";


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  
  seekeyresetpane = "N";
  alert_box_show = 'N';
  alert_box_text="";


formModel = {
UserName:'',
Password:''
}

resetbtnact='Y';


  constructor(private service:UserService, private router:Router,private toastr:ToastrService, private http:HttpClient) { } 

  ngOnInit() {
    if(localStorage.getItem('token') !=null)

    this.router.navigateByUrl('home');
    
  }


  
  onSubmit(form:NgForm)
  {

this.service.login(form.value).subscribe(
  (res: any) =>{
    localStorage.setItem('token',res.token);
    
   ///// this.sendloginfo();
    
    this.router.navigateByUrl('home');
  },
  err =>{
    
    this.alert_box_text = "Incorrect username or password', 'Authentication failed.";
  this.alert_box_show = 'Y';

if(err.status == 400)
this.toastr.error('Incorrect username or password', 'Authentication failed.');
else console.log(err)
  }
);
  }

 


  backtologin()
  {
    this.seekeyresetpane = "N";
  }

  gotorest()
  {
    this.seekeyresetpane = "Y";
  }

  pkeyreset()
  {
    if(this.resetbtnact == 'N')
    {
    
      this.alert_box_text = "Creating your reset link. One moment please.";
  this.alert_box_show = 'Y';
    }
    else{
      this.resetbtnact = 'N';

      var email =  (<HTMLInputElement>document.getElementById("emailtoreset")).value;;   
      
      if(confirm("Are you sure you want to reset password for account with emaill address: " + email)) 
      {
      
           this.service.resetkey(email).subscribe(
            res => {
              this.resetbtnact == 'Y'
      
              if(res == "No user")
              {
               
                this.alert_box_text = "There was not account found with this email address.";
                this.alert_box_show = 'Y';
              }
              else if (res == "Email Sent")
              {
            
                this.alert_box_text = "A password reset link has been send to email: " + email;
               this.alert_box_show = 'Y';

              (<HTMLInputElement>document.getElementById("emailtoreset")).value = "";
              }
              
            },
            err => {},
           )
          
      }
      else {this.resetbtnact == 'Y'}
    }



    
  }
  ok_alert()
  {this.alert_box_show = 'N'
  this.alert_box_text = "";
}


}
