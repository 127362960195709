import { Component, OnInit } from '@angular/core';
import { HomeService } from '../../home.service';
import { PlatformService } from '../../shared/platform.service';
import { Router } from '@angular/router';
import {NgxImageCompressService} from 'ngx-image-compress';
import { ConnectionsService } from '../../connections.service';
import { GroupService } from '../../group.service';

@Component({
  selector: 'app-mainfeed',
  templateUrl: './mainfeed.component.html',
  styleUrls: ['./../homebase.component.css']
})
export class MainfeedComponent implements OnInit {

  mainfeedcontent;
  person_icon = 'src/app/images/person_icon.png'; 
  default_link_pre_image = 'src/app/images/no_link_preview_3.png'; 
  offset = new Date().getTimezoneOffset();
  scrollposition;
  reportlist;
  usersid;  //User db_id
  pname;    //User id
  //iscurrentuserpage_flag=0;
  requserid;
  pageloadflag: boolean = false; 
  beforeloadY;
  upimgbeforcomp:string;
  upimgaftercomp:string;
  isVisible: boolean = false;
  reportbtn;
  editpane: number = -1;
  touchedblah;
  commenttext;
  postindexholder;
  addedTrooper;
  commentid;
  isfriend = 'N';
  thisprofiledbid;
  timezonepostnumber=7;
  newpost;
  justuserid;
  userid_noatt;
  serverdateoflastpostloaded;
  showpostbutton='N';
  show_loadmore = 'N';
  start_question: string = "original place holder";
  sys_notes;
  alert_box_show = 'N';
  alert_box_text="";

  timeLeft: number = 6;
  interval;
  timer_post_flag='Y';
  img_postion=1;
  loading_imgs = 'src/app/images/shapes/loading_img_01v3.png';
  mainfeedload = 'N';
  loadmore ='N';
  noposttoshow ='N';
  no_more_posttoshow = 'N'

  constructor(private connectservice : ConnectionsService,private groupservice :GroupService, private imageCompress: NgxImageCompressService,private homeservice: HomeService, private getstanderprofile : PlatformService, private router: Router) { }

  ngOnInit() {

 // force route reload whenever params change;
     this.router.routeReuseStrategy.shouldReuseRoute = () => false;

     

this.homeservice.checkifemailverified().subscribe(
  res => {      
    if(res == "Access_user_bu") { localStorage.removeItem('token'); this.router.navigate(['/login']);}


    if(res == 'Ok')
    {//check how found complemate

      this.homeservice.checkfoundcomplemate().subscribe(
        res2 => {
          
          if(res2 == "N")
          {
           // this.search_redirect("form/"); 
           this.router.navigateByUrl('/form');
          }
        },
        err => {}, 
       )
      
    }
    else if (res == 'No') 
    {
      this.router.navigateByUrl('/emailverification');

    }
  },
  err => {},
)

 



 

    window.addEventListener('scroll', () =>{
    
      const scrollable = document.documentElement.scrollHeight - window.innerHeight;
      const scrolled = window.scrollY;
      
 
       this.scrollposition = scrolled;
      });


this.getstanderprofile.PostformModel.reset();
this.getstanderprofile.CommentformModel.reset();
this.getstanderprofile.ReportModel.reset();


var dummy_main ={ "Numberofchildren":0}
    
    this.homeservice.getmainfeed(dummy_main).subscribe(
      res => {
     this.mainfeedcontent = res;

     if (res == null || res == "")
     {
       this.noposttoshow='Y';
     }


    this.mainfeedload = 'Y'
    
if (this.mainfeedcontent.length > 6)
{
this.show_loadmore = 'Y';
}

if (this.mainfeedcontent.length > 0)
{this.serverdateoflastpostloaded =  this.mainfeedcontent[this.mainfeedcontent.length - 1]["post_datetime"];}


     for (var x of this.mainfeedcontent)
     {
      var postdate = new Date(x['post_datetime']);
      var offsettime = new Date(postdate.getTime() + this.offset * -60 *1000 ) 
      x['post_datetime']= offsettime;
      x['post_text'] = this.createTextLinks(x['post_text']);

      if(x['profileimage'] ==  "" || x['profileimage'] == null)
      {
       
        x['profileimage'] = this.person_icon;
      }

      if(x['title'] != "" &&  x['image_path'] == "")
      {
        x['image_path'] =this.default_link_pre_image;
      }

     }
     this.showpostbutton='Y'; 

      },
      err => {
    
      },
    );



  //get report list
this.getstanderprofile.getreportlist().subscribe(
  res => {this.reportlist = res; 
  },
  err => {console.log("Error");},
)



//get current user id
this.getstanderprofile.getcurrent().subscribe(
  res =>{this.usersid = res; 
  },
  err => { console.log("Error mate");},
)


this.getstanderprofile.getpname().subscribe(
  res => {
    this.pname =res[0]['userid'];
    this.requserid =this.pname =res[0]['userid'];

  
  },
  err => {console.log("Not pname");},
);

    

    this.homeservice.getquestion().subscribe(
      res => {
this.start_question =res;


      },
      err => {},
    )


this.homeservice.get_sys_message().subscribe(
  res => {
    this.sys_notes = res; 
  },
  err => {},
)

setInterval(()=> { this.change_loading_img() }, 1 * 500);

  } //////////////////////////////////// last line of ngoninit

  search_redirect(uri:string) 
  {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate([uri]));
  }
 



  ngAfterViewChecked()

  {
  
    if(this.pageloadflag == false )
    {
    for (var z of this.mainfeedcontent)
    {
      if(z.video_link.length >5)
      { 
       
        (<HTMLInputElement>document.getElementById(z.postid+"video")).src = z.video_link;}
     
    }
  
    this.pageloadflag = true;
    window.scrollTo(0,this.beforeloadY);
  }//
  
  
  }


//loading image
change_loading_img()
{
  if (this.loading_imgs == "src/app/images/shapes/loading_img_01v3.png")
  {
    
  this.loading_imgs = "src/app/images/shapes/loading_img_02v3.png"
  }
  else{
  
  this.loading_imgs = "src/app/images/shapes/loading_img_01v3.png"
  }
}



//for images
upimg()
{

  this.imageCompress.uploadFile().then(({image, orientation}) => {
    this.upimgbeforcomp = image;
    console.warn('Size in bytes was:', this.imageCompress.byteCount(image));

    if(this.imageCompress.byteCount(image) > 300000)
    {

    this.imageCompress.compressFile(image, orientation, 30, 50).then(
      result => {

        // if size is greater than .3MB then image =15 or 10

        if( this.imageCompress.byteCount(result) >300000)
        {
          this.imageCompress.compressFile(image, orientation, 15, 50).then(
            smallerresult => {
              this.upimgaftercomp = smallerresult;
              console.warn('Size in bytes is now,smaller:', this.imageCompress.byteCount(smallerresult));


              if(this.imageCompress.byteCount(smallerresult) >300000)
              {
                this.imageCompress.compressFile(image, orientation, 10, 50).then(
                  smallerthirdresult => {
                    this.upimgaftercomp = smallerthirdresult;
                    console.warn('Size in bytes is now,3rd smaller:', this.imageCompress.byteCount(smallerthirdresult));

                    if(this.imageCompress.byteCount(smallerresult) >300000)
                    {
                      this.imageCompress.compressFile(image, orientation, 5, 50).then(
                        smallerforthresult => {
                          this.upimgaftercomp = smallerforthresult;
                          console.warn('Size in bytes is now,4th smaller:', this.imageCompress.byteCount(smallerforthresult));

                    })
                    }
                    else{
                      console.log("Image is too large");
                    }
                  })
              }
            })
        }
        else{
          this.upimgaftercomp = result;
          console.warn('Size in bytes is now:', this.imageCompress.byteCount(result));
        }
      }
    );

    }
    else
    {  this.upimgaftercomp =image;}

  

  });

}




removepostimage()
{
  this.upimgbeforcomp="";
  this.upimgaftercomp="";
}



submitreport(model,contentid, contenttype,tbl_gen)
{
 
  
  //sendreport
this.getstanderprofile.sendreport(contentid, contenttype,tbl_gen).subscribe(
  res => {
    console.log("Report sent");
    this.getstanderprofile.ReportModel.reset();
    if (this.isVisible) { // if the alert is visible return
      return;
    } 
    else{
      this.isVisible = true;
      setTimeout(()=> this.isVisible = false,3500); // hide the alert after 2.5s
    }


  },
  err => {console.log("Issue with sending report")}, 
)
}


reporttypechange(postid)
{
 
 this.reportbtn =postid;
}





deletepost(postid,indextoremove,tbl_gen)
{


  if(confirm("Are you  you want to delete this post?")) 
  {
    this.getstanderprofile.deletepost(postid,tbl_gen).subscribe(
      res => {


        if (indextoremove > -1) {
          this.mainfeedcontent.splice(indextoremove, 1);
       }


      },
      err => {
        console.log("Error mate");
      },
    )
  }
}

canceledit
(){this.editpane =-1;}

saveedit(postid,indexofedit,tbl_gen)
{
//get text by id
var updatetext = (<HTMLInputElement>document.getElementById(postid+"editarea")).value;

this.editpane =-1;

this.mainfeedcontent[indexofedit]["post_text"] =updatetext;

//submitt new text

this.getstanderprofile.editpost(postid,updatetext,tbl_gen).subscribe(
  res => {
    console.log("Ok");
  },
  err => {
console.log("Error mate");
  },
)


}


editthispost(postid: number, index,tbl_gen :number)
{
  this.mainfeedcontent[index]["post_text"] = this.mainfeedcontent[index]["post_text"].replace(/<[^>]*>/g, '');

this.editpane =postid;
}





 
onSubmit() {
  var isinputempty = this.getstanderprofile.PostformModel.value.PostText;
  isinputempty = isinputempty.replace(/ /g,"");
  
   if(isinputempty.length == 0)
   {
     this.alert_box_text = "Looks like your post is empty. Please type some text or add a link.";
     this.alert_box_show = 'Y';
   }
   else
   {
    

var justuserid = this.router.url.substr(1,this.router.url.length - 9)


this.timer_post_flag='N';

this.interval = setInterval(() => {
  if(this.timeLeft > 0)
   {
    this.timeLeft--;
  }
   else
   {
    clearInterval(this.interval)
    this.timer_post_flag='Y';
   }
},1000)

if(  (<HTMLImageElement>document.getElementById("mainimgupload")) != null)
{this.upimgaftercomp =(<HTMLImageElement>document.getElementById("mainimgupload")).src;}


this.getstanderprofile.makepost(this.pname,this.upimgaftercomp).subscribe(
  
res =>{


  if(res[0]["profileimage"] == "" || res[0]["profileimage"] == null)
{
  res[0]["profileimage"] =this.person_icon;
}

  this.mainfeedcontent.unshift({postid : res[0]["postid"],
    post_text : res[0]["post_text"],
    post_datetime : "Just now",
    action_clicked : 0,
    helpfulctcomment : 0,
    unhelpfulctcomment : 0,
    explainctcomment : 0,
    commcount : 0,
    firstname : res[0]["first_name"],
    lastname : res[0]["last_name"]
  ,  video_link :res[0]["video_link"]
  , image :res[0]["image"]
  , profileimage: res[0]["profileimage"]
  ,tbl_gen: res[0]["tbl_gen"]
  ,user_db_id_of_poster: res[0]["user_db_id_of_poster"]
    
  });
  this.pageloadflag = false;
 

  this.upimgbeforcomp="";
this.upimgaftercomp="";

  this.getstanderprofile.PostformModel.reset();
},
err =>{
  console.log(err);
},
);
}

}






oncommentChange(postid)
{
  this.touchedblah = postid;
  this.commenttext = (<HTMLInputElement>document.getElementById(postid)).value;

}



onCommentSubmit(troop: string,postindex: number,commentvalue: any,postid,tbl_gen: number, grp_id: number){
  if(grp_id == 0 || grp_id == null)  //for none group post
  {


    var isinputempty = this.getstanderprofile.CommentformModel.value.CommentText;
    isinputempty = isinputempty.replace(/ /g,"");
    
     if(isinputempty.length == 0)
     {
                     
       this.alert_box_text = "Looks like your post is empty. Please type some text or add a link.";
     this.alert_box_show = 'Y';
     }
     else
     {
    
    
    
    this.postindexholder = postindex;
    
    
    this.commenttext = (<HTMLInputElement>document.getElementById(commentvalue)).value;
   
    
    
      this.commentid = this.addedTrooper;
      this.getstanderprofile.makecomment(this.commentid,this.commenttext,tbl_gen).subscribe(
      
        res =>{
       
          var anothercomment = this.mainfeedcontent[this.postindexholder]["commcount"] + 1;
          this.mainfeedcontent[this.postindexholder]["commcount"] =anothercomment;
          this.getstanderprofile.CommentformModel.reset();
    
          this.router.navigateByUrl(this.requserid +"/" + postid +"/" + tbl_gen);
    
        },
        err =>{
          console.log(err);
        },
      );
    }
    
  }
  else if (grp_id > 0)// for group post
  {
     
  //var isinputempty = this.groupservice.CommentformModel.value.CommentText;
  var isinputempty = this.getstanderprofile.CommentformModel.value.CommentText;
  isinputempty = isinputempty.replace(/ /g,"");
  
   if(isinputempty.length == 0)
   {
                
     this.alert_box_text = "Looks like your post is empty. Please type some text or add a link.";
     this.alert_box_show = 'Y';
     
   }
   else
   {

  this.commenttext = (<HTMLInputElement>document.getElementById(commentvalue)).value;

   this.postindexholder = postindex;
  
      this.commentid = this.addedTrooper;
      this.groupservice.makecomment(this.commentid,this.commenttext,tbl_gen).subscribe(
      
        res =>{
  
  
          var anothercomment = this.mainfeedcontent[this.postindexholder]["commcount"] + 1;
          this.mainfeedcontent[this.postindexholder]["commcount"] =anothercomment;
          this.groupservice.CommentformModel.reset();

          this.router.navigateByUrl("group/" + grp_id + "/"+ postid + "/" + tbl_gen); 
        },
        err =>{
          console.log(err);
        },
      );
    }
  }




}

addToList(troop: number): void{
  this.addedTrooper = troop;

 
}





gotocomments(posidforcomments: number, tbl_gen: number, grp_id: number,sharepostid : number )
{

if(grp_id == 0 || grp_id == null || sharepostid > 0 )
{this.router.navigateByUrl(this.requserid +"/" + posidforcomments +"/" + tbl_gen);  //does this handel groups}
}
else if (grp_id > 0)
{
  this.router.navigateByUrl("group/" + grp_id +"/" + posidforcomments +"/" + tbl_gen); 
}
else{
  this.router.navigateByUrl("home/"); 
}
  
}
 


//actions for post
actions(postid : number,commentid :number, replyid: number, action : number, commentindex : number, tbl_gen : number, group_num :number, sharepostid :number)
{

  
  var currentselected = this.mainfeedcontent[commentindex]["action_clicked"];

  if(action == 1)
  {
   var helpnum = this.mainfeedcontent[commentindex]["helpfulctcomment"] + 1;
   this.mainfeedcontent[commentindex]["helpfulctcomment"] =helpnum;
   if(currentselected == 2) {var unhelpnum = this.mainfeedcontent[commentindex]["unhelpfulctcomment"] - 1; this.mainfeedcontent[commentindex]["unhelpfulctcomment"] =unhelpnum; }
   if(currentselected == 3) {var explainnum = this.mainfeedcontent[commentindex]["explainctcomment"] - 1; this.mainfeedcontent[commentindex]["explainctcomment"] =explainnum; }
  }
  else if (action == 2)
  {
    var unhelpnum2 = this.mainfeedcontent[commentindex]["unhelpfulctcomment"] + 1;
    this.mainfeedcontent[commentindex]["unhelpfulctcomment"] =unhelpnum2;
    if(currentselected == 1) {var helpnum2 = this.mainfeedcontent[commentindex]["helpfulctcomment"] - 1; this.mainfeedcontent[commentindex]["helpfulctcomment"] =helpnum2; }
    if(currentselected == 3) {var explainnum2 = this.mainfeedcontent[commentindex]["explainctcomment"] - 1; this.mainfeedcontent[commentindex]["explainctcomment"] =explainnum2; }
  }
  else if(action == 3)
  {
    var explainnum3 = this.mainfeedcontent[commentindex]["explainctcomment"] + 1;
    this.mainfeedcontent[commentindex]["explainctcomment"] =explainnum3;
    if(currentselected == 2) {var unhelpnum3 = this.mainfeedcontent[commentindex]["unhelpfulctcomment"] - 1; this.mainfeedcontent[commentindex]["unhelpfulctcomment"] =unhelpnum3; }
    if(currentselected == 1) {var helpnum3 = this.mainfeedcontent[commentindex]["helpfulctcomment"] - 1; this.mainfeedcontent[commentindex]["helpfulctcomment"] =helpnum3; }
 
  }
  else if (action ==0)
  {
    if(currentselected == 1) {var helpnum0 = this.mainfeedcontent[commentindex]["helpfulctcomment"] - 1; this.mainfeedcontent[commentindex]["helpfulctcomment"] =helpnum0; }
    if(currentselected == 2) {var unhelpnum0 = this.mainfeedcontent[commentindex]["unhelpfulctcomment"] - 1; this.mainfeedcontent[commentindex]["unhelpfulctcomment"] =unhelpnum0; }
    if(currentselected == 3) {var explainnum0 = this.mainfeedcontent[commentindex]["explainctcomment"] - 1; this.mainfeedcontent[commentindex]["explainctcomment"] =explainnum0; }
  }
 




this.mainfeedcontent[commentindex]["action_clicked"] = action;



if(group_num  == 0 || group_num  == null || sharepostid > 0)
{
  this.getstanderprofile.sendaction(postid,commentid,replyid,action,tbl_gen).subscribe(
    
    res =>{
      
      console.log("action was sent" );
      //this.getdata.ReplyformModel.reset();
    },
    err =>{
      console.log(err);
    },
  );
}
else{
 
  this.groupservice.sendaction(postid,commentid,replyid,action,tbl_gen).subscribe(
    
    res =>{
      
      console.log("action for reply was sent" );
      //this.getdata.ReplyformModel.reset();
    },
    err =>{
      console.log(err);
    },
  );
}




}



//Following method is to push post to top of post

///for sharing
sharepost(text,poster,date,image,postid,tbl_gen,grp_id)
{

  if(grp_id > 0)
  {
   
this.groupservice.sharepost(postid,tbl_gen).subscribe(
  res =>{

  
    this.alert_box_text = "You shared this post.";
    this.alert_box_show = 'Y';

  },
  err => {
    console.log("Error with share");
  },

)
  }
  else
  {
//console.log(text+poster+date+image);

this.getstanderprofile.sharepost(text,poster,date,image,postid,tbl_gen).subscribe(
  res =>{
    
    this.alert_box_text = "You shared this post.";
    this.alert_box_show = 'Y';
    
  },
  err => {
    console.log("Error with share");
  },

)
  }


}

loadmorepost()
{
  this.loadmore = 'Y';
  this.beforeloadY = window.scrollY;

  

// this is not the correct time, i need the
// date format that stored in db, perhaps just take the posttype, gen, and grab the time, or better yet
// save the date in a var on the client 

  this.timezonepostnumber =  this.timezonepostnumber+7;
  this.userid_noatt ={"Last_login_date":this.serverdateoflastpostloaded, "Numberofchildren":this.timezonepostnumber}

  this.homeservice.getmainfeed(this.userid_noatt).subscribe(
    res =>{
      



      this.newpost = res;

      if (this.newpost.length == 0)
        {
          this.no_more_posttoshow = "Y";
          this.loadmore = 'N'
        }

      this.serverdateoflastpostloaded =  this.newpost[this.newpost.length - 1]["post_datetime"];
   
      

      for (var x of this.newpost)
      {
        var postdate = new Date(x['post_datetime']);
        var offsettime = new Date(postdate.getTime() + this.offset * -60 *1000 )
        x['post_datetime']= offsettime;
        x['post_text'] = this.createTextLinks(x['post_text']);


        if(x['profileimage'] ==  "" || x['profileimage'] == null)
        {
         
          x['profileimage'] = this.person_icon;
        }
  
        if(x['title'] != "" &&  x['image_path'] == "")
        {
          x['image_path'] =this.default_link_pre_image;
        }
        
      }


for (var n of this.newpost)
{
  
this.mainfeedcontent.push(n);
this.pageloadflag = false;
this.loadmore = 'N';

}


    },
    err =>{
      console.log(err);
    },
    
  );
}


unfriend()
{

  this.connectservice.unfriend(this.requserid).subscribe(
    res => {
      this.router.navigateByUrl('/home');
    },
    err => {
      console.log("There was an error trying to unfriend, maybe it was ment to be");
    },
  )
}


gotomessages(id)
{
  this.search_redirect("messages/" + id); 

}




  createTextLinks(text) {

    return (text || "").replace(
      /([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi,
      function(match, space, url){
        var hyperlink = url;
        if (!hyperlink.match('^https?:\/\/')) {
          hyperlink = 'http://' + hyperlink;
        }
        return space + '<a target="_blank" rel="noopener noreferrer" href="' + hyperlink + '">' + url + '</a>';
      }
    );
  };


  
gotoeditprofile()
{
 this.router.navigateByUrl('/editprofile');
}


gotouserprofile_fdb(updbid) //using user's dbid
{ 
 this.connectservice.getuserid(updbid).subscribe(

   res => {
     var userid = res;
  //  this.search_redirect(userid + "/profile");

 this.connectservice.ssuserprofile(userid).subscribe(
   res => {
     var ssgoorno = res;

     if(ssgoorno == 'Y')
     {
      // this.search_redirect(userid + "/profile");
      this.router.navigateByUrl(userid + "/profile");
     }
     else{
       this.router.navigateByUrl("preview/" + userid );
     }



   },
   err => {},
 )



   },
   err => {console.log("Something went wrong with getting user's ID")},
 )
}


sendrequest()
{


this.connectservice.sendconrequest(this.thisprofiledbid,1).subscribe( 
  res =>{ 
//;   //< why?

this.alert_box_text = "Your request was sent.";
this.alert_box_show = 'Y';

this.isfriend = 'P';

//add friend button needs to go away

  },
  err => {
  },

)
}


gotogroup(grp_id: number)
{
 //this.search_redirect("group/" + grp_id + "/home");
 this.router.navigateByUrl("group/" + grp_id + "/home");
}

 

act_note(id,index)
{
  this.homeservice.act_sys_note(id).subscribe( 
    res => {
      this.sys_notes.splice(index,1)
    },
    err => {},
  )
} 


ok_alert()
  {this.alert_box_show = 'N';
  this.alert_box_text = "";
}



resetOrientation(srcBase64, srcOrientation, callback) {
  var img = new Image();    

  img.onload = function() {
    var width = img.width,
        height = img.height,
        canvas = document.createElement('canvas'),
        ctx = canvas.getContext("2d");

    // set proper canvas dimensions before transform & export
    if (4 < srcOrientation && srcOrientation < 9) {
      canvas.width = height;
      canvas.height = width;
    } else {
      canvas.width = width;
      canvas.height = height;
    }

    // transform context before drawing image
    switch (srcOrientation) {
      case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
      case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
      case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
      case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
      case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
      case 7: ctx.transform(0, -1, -1, 0, height, width); break;
      case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
      default: break;
    }

    // draw image
    ctx.drawImage(img, 0, 0);

    // export base64
    callback(canvas.toDataURL());
  };

  img.src = srcBase64;
};

rotate_this_image(imagetoturnid)
{
 
 if(this.img_postion == 1) 
 {this.img_postion = 8;}
 else if (this.img_postion == 8)
 {this.img_postion = 3;}
 else if(this.img_postion == 3)
 {this.img_postion = 6;}
 else if (this.img_postion == 6)
 {this.img_postion = 1;}
 
  var resetImageresetImage =(<HTMLImageElement>document.getElementById(imagetoturnid))
  this.resetOrientation(resetImageresetImage.src, this.img_postion, function(resetBase64Image) {
  resetImageresetImage.src =resetBase64Image;
  });
}








}
