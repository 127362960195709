import { Injectable } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { createTokenForExternalReference } from '@angular/compiler/src/identifiers';


@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  constructor(private fb:FormBuilder, private http:HttpClient) { }

  //readonly BaseURI = 'https://localhost:44348/api/';         //DEV
 // readonly BaseURI = 'http://complemate.com:5000/api/';    // PRD No SSL
 readonly BaseURI = 'https://complemate.com:443/api/';    // PRD SSL  


  standarduserprofile(passerhere)
  {
 
    return this.http.post(this.BaseURI+'UserProfile/standarduserprofile/', passerhere) 

  }



  PostformModel = this.fb.group({
    PostText :['',[Validators.required,Validators.minLength(3)]]
        
    });

    makepost(user_id,image){
      var postbody = {
      
        User_profile_id : user_id,
        Post_text : this.PostformModel.value.PostText,
        Post_datetime: new Date(),
        Image :image
  
      };
    return this.http.post(this.BaseURI+'UserProfile/postpost/', postbody); 

    }



CommentformModel = this.fb.group({
  CommentText :['',[Validators.required,Validators.minLength(1)]]
 ,Commentidactive :['',[Validators.min(1)]]
})

makecomment(post_id,com_text,tbl_gen)
{


var commentbody={
  Post_id : post_id,
  Comment_text : com_text,
  Tbl_gen: tbl_gen
    
};


return this.http.post(this.BaseURI+'UserProfile/postcomment/', commentbody, {responseType: 'text'});
    
}

////////////Same as above but for Comments post on comments page

CommentformModelonC = this.fb.group({
  CommentTextonC :['',[Validators.required]]
 ,CommentidactiveonC :['',[Validators.min(1)]]
})

makecommentonC(post_id, image,tbl_gen)
{


var commentbodyonC={
  Post_id : post_id,
  Comment_text : this.CommentformModelonC.value.CommentTextonC,
  Image: image,
  Tbl_gen:tbl_gen

    
};


return this.http.post(this.BaseURI+'UserProfile/postcomment/', commentbodyonC);
    
}





ReplyformModel = this.fb.group({
  ReplyText:['',[Validators.required]]
})

makereply(commentID,replytext,image,tbl_gen)
{
  var replybody ={
    Parent_comment_id :commentID,
    Comment_text : replytext,
    Image: image,
    Tbl_gen: tbl_gen
  }

return this.http.post(this.BaseURI+'postcomments/postreply/', replybody);
   

}



//Group registration model
GroupRegModel = this.fb.group({
  Groupname:['',[Validators.required,Validators.min(3)]]
  ,Visibility:['',[Validators.required]]
  ,Listed:['',[Validators.required]]
  ,Permission:['',[Validators.required]]
  ,Summary:['',[Validators.required,Validators.min(5)]]
  ,Aboutyou:['',[]]
  ,Website:['',[]]
  ,Grpimage:['',[]]
  ,Approve:['',[Validators.required]]
})

CreateGroup(image) 
{
var grpsubbody ={
  Grp_name : this.GroupRegModel.value.Groupname,
  Grp_visibility : this.GroupRegModel.value.Visibility,
  Listed : this.GroupRegModel.value.Listed,
  Permission : this.GroupRegModel.value.Permission,
  Summary : this.GroupRegModel.value.Summary,
  About : this.GroupRegModel.value.Aboutyou,
  Website : this.GroupRegModel.value.Website,
  Image : image,
  Approveusers : this.GroupRegModel.value.Approve

}
//need to call api after this
return this.http.post(this.BaseURI+'groups/groupreg/', grpsubbody);
   

} 



sendaction(postactionid,Commentid,replyid,theaction,tbl_gen)
{
  var actionbody={
    Post_id : postactionid,
    Comment_id : Commentid,
    Parent_comment_id :replyid,
    Action_clicked :theaction,
    Tbl_gen : tbl_gen
  }
  return this.http.post(this.BaseURI+'postcomments/postaction/', actionbody, {responseType: 'text'});
 

}


getcurrent()
{
  var user ={
    Userid:"null"
  }
  return this.http.post(this.BaseURI+'UserProfile/getcurrent/', user, {responseType: 'text'})
}

getpname()
{
  var user ={
    Userid:"null"
  }
  return this.http.post(this.BaseURI+'UserProfile/pname/', user)  
}




//Get UIpost content here


getprofilepost(profile_idfor_post)
  {
    
   
    return this.http.post(this.BaseURI+'UserProfile/profilefeed/', profile_idfor_post)
  }


//Get single post for comments
getsinglepost(singlepostid)
  {
  
    return this.http.post(this.BaseURI+'postcomments/getmainpost/', singlepostid)
  }
  

  
//Get  comments for single post
getcomments(postforcomments)
{
  return this.http.post(this.BaseURI+'postcomments/getcomments/', postforcomments)
}


//Get children comments
getchildcomments(postforchildcomments)
{
  return this.http.post(this.BaseURI+'postcomments/getchildcomments/', postforchildcomments)
}


getmorereplies(post_id,comment_id, count,tbl_gen)
{

var comment_info = {
  Post_id:post_id,
  Comment_id :comment_id,
  Visibility: count,
  Tbl_gen: tbl_gen
}

  return this.http.post(this.BaseURI+'postcomments/getmorereplies/', comment_info)
}



////////////////////////////////////// For edits

pullprofile()
{
  var uprofile={}
  return this.http.post(this.BaseURI+'edit/getuserprofile/', uprofile)
}



EditProfileModel = this.fb.group({
  Firstname:['',[]]
 ,Lastname:['',[]]
 ,Intro:['',[]]
 ,Maritalstatus:['',[]]
 ,MarriageDate:['',[]]
 ,Otherscanpost:['',[]]
 ,State:['',[]]
 ,City:['',[]]
 ,Zipcode:['',[]]
 ,Religion:['',[]]
 ,Country:['',[]]
 ,Seepost:['',[]]

})


updateprofile(profilepic, bannerimage)
{
   
  this.EditProfileModel.value.Firstname = this.EditProfileModel.value.Firstname.replace(/ /g, "");
  this.EditProfileModel.value.Lastname = this.EditProfileModel.value.Lastname.replace(/ /g, "");

  this.EditProfileModel.value.Lastname = this.EditProfileModel.value.Lastname.replace(/[^a-zA-Z ]/g, "");
  this.EditProfileModel.value.Firstname = this.EditProfileModel.value.Firstname.replace(/[^a-zA-Z ]/g, "");


  var profilespec={
    First_name: this.EditProfileModel.value.Firstname,
    Last_name : this.EditProfileModel.value.Lastname,
    Intro: this.EditProfileModel.value.Intro,
    Marital_status: this.EditProfileModel.value.Maritalstatus,
    Marriage_date: this.EditProfileModel.value.MarriageDate,
    Allowotherstopost: this.EditProfileModel.value.Otherscanpost,
    State: this.EditProfileModel.value.State,
    City: this.EditProfileModel.value.City,
    Zip: this.EditProfileModel.value.Zipcode,
    Religion: this.EditProfileModel.value.Religion,
    Country: this.EditProfileModel.value.Country,
    Privacy: this.EditProfileModel.value.Seepost,
    Profileimage: profilepic,
    Bannerimage: bannerimage,

  }

  return this.http.post(this.BaseURI+'edit/updateprofile/', profilespec,  {responseType: 'text'})
}


sharepost(text,poster,date,image,postid,tbl_gen)
{
  var theshare={
    User_db_id_of_poster:poster,
        Post_text : text,
        Post_datetime: date,
        Image :image,
        Postid: postid,
        Tbl_gen:tbl_gen

   
  }
  return this.http.post(this.BaseURI+'UserProfile/sharepost/', theshare, {responseType: 'text'});
 

}


editpost(postid,text,tbl_gen)
{
 var editwith={
  Postid:postid,
  Post_text:text,
  Tbl_gen:tbl_gen
 } 

  return this.http.post(this.BaseURI+'edit/editpost/', editwith,  {responseType: 'text'})
}


editcomment(commentid,updatetext,image,tbl_gen)
{

  var editcomwith={
    Comment_id:commentid,
    Comment_text:updatetext,
    Image:image,
    Tbl_gen: tbl_gen

   } 
  return this.http.post(this.BaseURI+'edit/editcomment/', editcomwith,  {responseType: 'text'})
}





deletepost(postid,tbl_gen)
{
  var deletethis ={Postid:postid, Tbl_gen:tbl_gen} 
 
  return this.http.post(this.BaseURI+'edit/deletepost/', deletethis,  {responseType: 'text'})
}

deletecomment(Commentid,tbl_gen)
{
  var deletethiscom ={Comment_id:Commentid, Tbl_gen:tbl_gen} 
  return this.http.post(this.BaseURI+'edit/deletecomment/', deletethiscom,  {responseType: 'text'})
}
//deletereply
deletereply(Commentid,tbl_gen)
{
  var deletethiscom ={Comment_id:Commentid, Tbl_gen: tbl_gen} 
  return this.http.post(this.BaseURI+'edit/deletereply/', deletethiscom,  {responseType: 'text'})
}



getreportlist()
{ var canget ={Gender:3}
  return this.http.post(this.BaseURI+'edit/reporttypes/', canget)
}



//ReportModel
ReportModel = this.fb.group({
  Report_type:['',[Validators.required]]
 ,Reporttext:['',[]]

})

sendreport(contentid,contenttype,tbl_gen)
{ var reportpack ={
  Content_id:contentid,
  Report_type: this.ReportModel.value.Report_type,
  Content_type: contenttype,
  Other_details: this.ReportModel.value.Reporttext,
  Tbl_gen:tbl_gen,
}


  return this.http.post(this.BaseURI+'edit/submitreport/', reportpack,  {responseType: 'text'})
}







getuserinfomenu()
{ var menuname={ Userid: "foo"}

return this.http.post(this.BaseURI+'UserProfile/getuserinfomenu/', menuname)
}

getgender()
{
var dummy={User_db_id : "blah"}

  return this.http.post(this.BaseURI+'UserProfile/getgender/', dummy, {responseType: 'text'})

}



get_loadcountriesstates()
{
  var dummy={List_id : 0}
  return this.http.post(this.BaseURI+'UserProfile/loadcountriesstates/', dummy)


}


delete_account()

{
  var dummy={List_id : 0}
  return this.http.post(this.BaseURI+'UserProfile/delete_account/', dummy)


}


}
