import { Component, OnInit, NgZone } from '@angular/core';
import { NgModel, NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { post } from 'selenium-webdriver/http';
import { UserService } from '../../shared/user.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'registrationComponent',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})

export class RegistrationComponent implements OnInit {

  constructor(public service: UserService, private toastr: ToastrService,private router:Router) { }
  

  datetimenowhtml;
  signup ='Y';
  alert_box_show = 'N';
  alert_box_text="";


  ngOnInit() {

this.datetimenowhtml = Date.now()

    this.service.formModel.reset();

    if(localStorage.getItem('token') !=null)
    this.router.navigateByUrl('home');

  }

  onSubmit() {
    var isfirstnameemt = this.service.formModel.value.First_name ;
    var islastnaememt = this.service.formModel.value.Last_name ;
    var pword = this.service.formModel.value.Password;
    var pwordrs = pword.replace(/ /g, "");
    
    isfirstnameemt = isfirstnameemt.replace(/ /g, "");
    islastnaememt = islastnaememt.replace(/ /g, "");

    islastnaememt = islastnaememt.replace(/[^a-zA-Z ]/g, "");
    isfirstnameemt = isfirstnameemt.replace(/[^a-zA-Z ]/g, "");
    

    if(isfirstnameemt.length < 2)
    {
    this.alert_box_text = "First name must be 2 or more characters. Only letters.";
    this.alert_box_show = 'Y';
  }
    else if (islastnaememt.length < 2)
    {
    this.alert_box_text = "Last name must be 2 or more characters. Only letters.";
    this.alert_box_show = 'Y';
  }
    else if (pword.length != pwordrs.length)
    {
    this.alert_box_text = "Password cannot contain spaces";
    this.alert_box_show = 'Y';
  }
    else{

      

    this.service.register().subscribe(
      (res: any) => {
        if (res.succeeded) {
          this.service.formModel.reset();
          this.toastr.success('Thank you for signing up. Time to get started.', 'Registration Sucessfull');
        } else {
          res.errors.forEach(element => {
            switch (element.code) {
              case 'DuplicateUserName':
                this.toastr.error('Username is already taken', 'Registration failed');
   
                break;

              default:
                this.toastr.error(element.description, 'Registration failed');
                break;
            }
          })

        }
      },
      err => {
        console.log(err);
      }
    )
    }
  }

  signinclick()
  {
    this.signup ='N';
  }

  signupclick()
  {
    this.signup ='Y';
  }
  ok_alert()
  {this.alert_box_show = 'N'
  this.alert_box_text = "";
}



}
