import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from "@angular/common/http";
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { PlatformService } from './shared/platform.service';

@Injectable({
  providedIn: 'root'
})
export class SmithService {

  constructor( private http:HttpClient,private platforbaseur: PlatformService) { }

  readonly BaseURI =this.platforbaseur.BaseURI;

check_smith_access()
{
  var pack ={User_db_id:""}
  return this.http.post(this.BaseURI+'Smith/check_smith_access/', pack, {responseType: 'text'});
}


get_info(type,id,userdbid)
{ 
   var pack={};

if(type == "userprofile")
  {
    pack = 
    { First_name:type,
      Phone_number:0,
      User_db_id:userdbid
     }


  }
  else{
    pack = 
    { First_name:type,
      Phone_number:id,
      User_db_id:userdbid
     }
  }


  return this.http.post(this.BaseURI+'Smith/see_image/', pack)
}

}
