import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { UserRegistrationComponent } from './user-registration/user-registration.component'
import { AppRoutingModule, rountingComponents } from './app-routing.module'
import { Log_in } from './log-in/log-in.component'
import { Homebase } from './homebase/homebase.component';
import { RegistrationComponent } from './user-registration/registration/registration.component';
import { UserService } from './shared/user.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { LoginComponent } from './user-registration/login/login.component';
import { AuthInterceptor } from './auth/auth.interceptor';
import { UserinfoComponent } from './homebase/userinfo/userinfo.component';
import { CmcategoriesComponent } from './homebase/cmcategories/cmcategories.component';
import { MainfeedComponent } from './homebase/mainfeed/mainfeed.component';
import { InformationrotationComponent } from './homebase/informationrotation/informationrotation.component';
import { ConversationsComponent } from './homebase/conversations/conversations.component';
import { SearchComponent } from './search/search.component';
import {NgxImageCompressService} from 'ngx-image-compress';
import { SamllhamburgerComponent } from './homebase/samllhamburger/samllhamburger.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { SharedModule } from './shared.module';



import {enableProdMode} from '@angular/core';


//import { CheckOutComponent } from './check-out/check-out.component';

enableProdMode();


@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    rountingComponents,
    Log_in,
    Homebase,
    RegistrationComponent,
    LoginComponent,
    UserinfoComponent,
    CmcategoriesComponent,
    MainfeedComponent,
    InformationrotationComponent,
    ConversationsComponent,
    SearchComponent,
    SamllhamburgerComponent,
    SubscriptionComponent,
    UserRegistrationComponent,
    
    
   
  ],
  imports: [//MatLinkPreviewModule.forRoot(),
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    //ReactiveFormsModule,
   BrowserAnimationsModule,SharedModule,    
    
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
    ////////  { path: 'counter', component: CounterComponent },
    /////  { path: 'fetch-data', component: FetchDataComponent }, 
    ])
    ,ToastrModule.forRoot()
    //,NgxLinkPreviewModule
  
  ],
 //////////// exports:[ControlBar],
  providers: [UserService, {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
  },
  NgxImageCompressService],
  
  bootstrap: [AppComponent]
})
export class AppModule { }

