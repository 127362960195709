import { Component } from '@angular/core';
import { Button } from 'protractor';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NgModel, NgForm } from '@angular/forms';
import { Text } from '@angular/compiler/src/i18n/i18n_ast';
import { getLocaleDateTimeFormat } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../shared/user.service'; 

import { post } from 'selenium-webdriver/http';
import { Router } from '@angular/router';
import { PlatformService } from './../shared/platform.service';
//import { ApiService} from '/'
//import { HttpClientModule } from '@angular/common/http';
//import { NgModel } from '@angular/core';




@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.css']
})
  

export class HomeComponent {


  datetimenowhtml;
  signup ='Y';
  oncreatesubmit = 'Y';
  alert_box_show = 'N';
  alert_box_text="";
  affiliate_link ="N";



 


  url = 'https://localhost:44385/api/subscribe_con_ent';
  //url = 'https://complemate.com:5000/api/subscribe_con_ent'; 
  readonly BaseURI =this.platforbaseur.BaseURI; 

constructor(private http: HttpClient,public service: UserService, private toastr: ToastrService,private router:Router, private platforbaseur: PlatformService) { }

  myFunct(postDataa) { this.http.post(this.url, postDataa).toPromise(); }
  ngOnInit() { 

  var linkq = new String( this.router.url);
if(linkq.substring(linkq.length - 8) == 'signin=y')
{
 
this.signinclick();
}

this.send_hit_stats();
this.send_hit_stats_noneip();
//insertfromreg

this.datetimenowhtml = Date.now()

    this.service.formModel.reset();

    if(localStorage.getItem('token') !=null)
    this.router.navigateByUrl('home');

}
  public usedemail: string;
  
  public twofunction(useremailinput)
  {
    var checkmail = this.url + "/" + useremailinput
    var thereturned;   
    
    return this.http.get(checkmail, { responseType: 'text' });
  }

 
  



  onclick(subcribeii) {

    // Perhaps this can be used for other things that need to happend on button click

  }



  onSubmit() {
    if(this.oncreatesubmit == 'Y')
    {
      this.oncreatesubmit = 'N';
    var isfirstnameemt = this.service.formModel.value.First_name ;
    var islastnaememt = this.service.formModel.value.Last_name ;
    var pword = this.service.formModel.value.Password;
    var pwordrs = pword.replace(/ /g, "");
    
    isfirstnameemt = isfirstnameemt.replace(/ /g, "");
    islastnaememt = islastnaememt.replace(/ /g, "");

    islastnaememt = islastnaememt.replace(/[^a-zA-Z ]/g, "");
    isfirstnameemt = isfirstnameemt.replace(/[^a-zA-Z ]/g, "");
    
   
    //dob_check
    var dob_check= new Date();
    dob_check = this.service.formModel.value.DOB;
    var age = new Date().getTime() - new Date(dob_check).getTime();
    
    if(isfirstnameemt.length < 2)
    {
    this.alert_box_text = "First name must be 2 or more characters. Only letters.";
    this.alert_box_show = 'Y';
    this.oncreatesubmit = 'Y';
    
  }
    else if (islastnaememt.length < 2)
    {
    this.alert_box_text = "Last name must be 2 or more characters. Only letters.";
    this.alert_box_show = 'Y';
    this.oncreatesubmit = 'Y';
  }
  else if (age/86400000 < 6570)
  {
    this.alert_box_text = "Must be 18 years of age or older.";
    this.alert_box_show = 'Y';
    this.oncreatesubmit = 'Y';
  }
    else if (pword.length != pwordrs.length)
    {
    this.alert_box_text = "Password cannot contain spaces.";
    this.alert_box_show = 'Y';
    this.oncreatesubmit = 'Y';
  }
    else{


    this.service.register().subscribe(
res => {

 
if(res == 'EMAILUSED' )
{

 
  this.alert_box_text = "This email is being used already. If you forgot your password, on the sign in screen click 'Forgot password?' ";
  this.alert_box_show = 'Y';
}
else if (res != 'EMAILUSED')
{

  
  this.alert_box_text = "Thank you for signing up. You can login now with the email and password you signed up with.";
  this.alert_box_show = 'Y';
  
}
this.oncreatesubmit = 'Y';
this.service.formModel.reset();

},
err => {
  this.oncreatesubmit = 'Y';
  
  this.alert_box_text = "There was an error. Please try again.";
  this.alert_box_show = 'Y';
},

    )
    }
    }
    else{
      
      this.alert_box_text = "One moment, processing reques.";
      this.alert_box_show = 'Y';
    }
    
  }

  signinclick()
  {
    this.signup ='N';
  }

  signupclick()
  {
    this.signup ='Y';
  }




  sub_up_submit()
  {


    this.service.sub_up_submit().subscribe(
      res => {
if(res == 'Subscribed_ok')
{
  
  this.alert_box_text = "Thank you for subscribing. You will be notified of Complemate updates.";
  this.alert_box_show = 'Y';


}
else if (res == 'EmailAlreadySubscribed')
{ 
this.alert_box_text = "Thank you for subscribing. You will be notified of Complemate updates.";
this.alert_box_show = 'Y';
}

       
        this.service.subscription_up.reset();
      },
      err => {},
    )
  }



  terms()
  {
    
  }

  privacy()
  {}
  
  cookies()
  {}

  ok_alert()
  {
        if(this.alert_box_text == 'Thank you for signing up. You can login now with the email and password you signed up with.')
        {

          this.alert_box_show = 'N'
          this.alert_box_text = "";
          this.signinclick();
        }
        else{
          this.alert_box_show = 'N'
          this.alert_box_text = "";
        }   
}

send_hit_stats()
{

 const user_date = new Date();
 var user_ip;
 var user_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
 var is_mobile ="";
 var user_browser_name;
 var user_browser_version;
 var user_os;
 var thisroute;
 var user_datetimestring =user_date.toString();

this.http.get("https://api.ipify.org/?format=json").subscribe((res:any)=>{
  user_ip =    res.ip;



  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){ 
    is_mobile="Y";
  }
  else if(!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
  {
    is_mobile="N";
  }
  else{is_mobile="E";}
  
  const { detect } = require('detect-browser');
  const browser = detect();
  
  
  if (browser) {
    user_browser_name = browser.name;
    user_browser_version = browser.version;
    user_os = browser.os;
  }
  
  thisroute = this.router.url;
  
  
  

this.service.send_hit_data(
  user_ip,user_browser_name,user_browser_version,user_os,is_mobile,user_timezone,thisroute,user_date,user_datetimestring
  
).subscribe(
  res => {},
  err => {},
)
    
    });
}

send_hit_stats_noneip()
{

 const user_date = new Date();

 var user_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
 var is_mobile ="";
 var user_browser_name;
 var user_browser_version;
 var user_os;
 var thisroute;
 var user_datetimestring =user_date.toString();


  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){ 
    is_mobile="Y";
  }
  else if(!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
  {
    is_mobile="N";
  }
  else{is_mobile="E";}
  
  const { detect } = require('detect-browser');
  const browser = detect();
  
  if (browser) {
    user_browser_name = browser.name;
    user_browser_version = browser.version;
    user_os = browser.os;
  }
  thisroute = this.router.url;

this.service.send_hit_data(
  'none_ip',user_browser_name,user_browser_version,user_os,is_mobile,user_timezone,thisroute,user_date,user_datetimestring
  
).subscribe(
  res => {},
  err => {},
)
    
    
}


show_a_link()
{
  if(this.affiliate_link == "N")
    {
      this.affiliate_link = "Y";
    }
    else if (this.affiliate_link == "Y")
      {
        this.affiliate_link = "N";
      }

}

copy_link()
{
  var copyText = (<HTMLInputElement>document.getElementById("affiliate_link"));
  //(<HTMLInputElement>document.getElementById(z.postid+"video")).src
  

  // Select the text field
  copyText.select();
  copyText.setSelectionRange(0, 99999); // For mobile devices

  // Copy the text inside the text field
  
  window.navigator['clipboard'].writeText(copyText.value);
  
  // Alert the copied text
  //alert("Copied the text: " + copyText.value);
  this.alert_box_text = "Link copied to clipboard.";
  this.alert_box_show = 'Y';

}





}
