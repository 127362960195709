import { Component } from '@angular/core';
import { NgModel, NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { post } from 'selenium-webdriver/http';



@Component({
  selector: 'log-in',
  templateUrl: './log-in.component.html',  
  styleUrls: ['./log-in.component.css']
})

export class Log_in {

  constructor(private http: HttpClient) { }

  url = 'https://localhost:44385/api/Login_';
  
  public check_creds(emailpassing,keypassing)
  {
   

    var checkemail = this.url + "/" + emailpassing + "/" + keypassing;

    return this.http.get(checkemail, { responseType: 'text' });
   
    
  }


  isExpanded = false;

  collapse() {
    this.isExpanded = false;
  }
  
  onSubmit(login: NgForm) {
    const login_form_inputs: any[] = login.value;
    var email = login_form_inputs["email_a"];
    var password = login_form_inputs["password_a"];

  
    this.check_creds(email, password).subscribe((postss) => {
 
    });
    

  }



  toggle() {
    this.isExpanded = !this.isExpanded;
  }
}
