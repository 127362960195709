import { Component } from '@angular/core';
import { NgModel, NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { post } from 'selenium-webdriver/http';
import { Router } from '@angular/router';
import { ConnectionsService } from '../connections.service';
import { UserService } from '../shared/user.service';
import { SmithService } from '../smith.service';



@Component({
  selector: 'control-bar',
  templateUrl: './control-bar.component.html',
  styleUrls: ['./control-bar.component.css']
})

export class ControlBar {

  isExpanded = false;

  // There should start off as 0
  friend_request= 1;
  //notifications= 1;
  messages= 1;
  singletownflag ='M'; 

  bardata; 

  notes_red_c=0;
  request_red_c=0;
  messages_red_c=0;
mobile_search_box_flag="N";

prelaunch ='Y'
us_or_no ='N';
alert_box_show = 'N';
alert_box_text="";
showsmith = 'N';


  constructor(private http: HttpClient,private router:Router,private connectservice : ConnectionsService, private service:UserService, private smithservice:SmithService) { }

  
  ngOnInit(){

    this.connectservice.SearchFormModel.reset();

    this.service.app_launch_check().subscribe(
      res => {
        this.prelaunch = res;
      },
      err => {},
    
    )

    this.check_if_us()
this.connectservice.checksingletown("blah").subscribe( 
  res => {

    this.singletownflag = res;

  },
  err => {},
)

this.connectservice.controlbarinfo().subscribe(
  res => {
this.bardata = res; 




if(this.bardata[0]['new_notes'] > 0)
{
  this.notes_red_c = 1;
}



if(this.bardata[0]['convoupdate'] > 0)
{
  this.messages_red_c = 1; 
}


if(this.bardata[0]['total_request'] > 0)
{
  this.request_red_c = 1;
}


  },
  err => {},
)


this.smithservice.check_smith_access().subscribe(
  res => {
 if (res == "Access")
  {
    this.showsmith = "Y";
  }

  },
  err => {},
)

    //  Need to get counts for friend_request,notifications, and message
    // If count is zero then red circle doesn't appear
    // Use *ngIf on the span's in the HTML to remove the circle


  }  /////////////////////////////////////////////////// End of ngoninit


  gosearch_redirect(uri:string) 
  {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate([uri]));
  }



search_redirect(uri:string) 
  {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate([uri]));
  }

  searchcomplemate(search)
  {
   // this.search_redirect("searchcomplemate/" + search['SearchText']); 
    //this.search_redirect("searchcomplemate/");

    this.router.navigateByUrl("searchcomplemate/" + search['SearchText']);
  } 


  gotomessages()
  {
    if(this.messages != 0)
    {this.resetnote(3);}
        this.messages =0;
        this.bardata[0]['convoupdate'] =0;

   // this.search_redirect("messages/"); 
    this.router.navigateByUrl("messages/");

  }



  collapse() {
    this.isExpanded = false;
  }
  // Add something add something else

  onLogout(){
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
  }
  

  gotodating()   
  {
    //figure out if user has a profile or is in the middle of dating registration to determine which componet/page to send them to

    if(this.singletownflag == "A")
    {
      if(this.prelaunch == 'Y' )
      {
        this.alert_box_text = "You have already signed up. Please come back after the Complemate launch.";
        this.alert_box_show = 'Y';
      }
      else{
       this.gosearch_redirect("findamate/" );
      }

    }
    else if(this.singletownflag == "S")
    {
     this.router.navigateByUrl('/datingfinalsteps'); 
    }
    else
    {
    this.router.navigateByUrl('/datingsetup');
    }

  }


  toggle() {
    this.isExpanded = !this.isExpanded;
  }



  friend_request_click()
  {

if(this.friend_request != 0)
{this.resetnote(2);}

    this.request_red_c =0;
    this.bardata[0]['total_request'] = 0;
    //reset_request



  }

  notifications_click()
  {
    if(this.notes_red_c != 0)
{this.resetnote(1);}

    this.notes_red_c =0;
    this.bardata[0]['new_notes'] =0;
  }

  messages_click()
  {
  
  }

  gohome()
  {
    this.search_redirect("home/"); 
  }

resetnote(type)
{
  this.connectservice.reset_note_number(type).subscribe(
    res => {
      console.log("Done");
    },
    err => {},
  )
}



search_mobile_click()
{
 
  if(this.mobile_search_box_flag == "N")
  {
    this.mobile_search_box_flag = "Y";
  }

  else if(this.mobile_search_box_flag == "Y")
  {
    this.mobile_search_box_flag = "N";
  }

}

check_if_us()
{
 var tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
 
if (tz == 'America/Chicago'
|| tz == 'America/Denver'
|| tz == 'America/Phoenix'
|| tz == 'America/Los_Angeles'
|| tz == 'America/Anchorage'
|| tz == 'Pacific/Honolulu'
)
{this.us_or_no = 'Y'}
else{this.us_or_no = 'N'}


}

ok_alert()
  {this.alert_box_show = 'N'
  this.alert_box_text = "";
}

smith()
{
  this.router.navigateByUrl("smith");
}


}
