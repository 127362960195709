import { Component, OnInit, NgZone } from '@angular/core';
import { NgModel, NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { post } from 'selenium-webdriver/http';
import { UserService } from '../shared/user.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-regis',
  templateUrl: './user-registration.component.html'  
  ,styleUrls: ['./user-registration.component.css']
})

export class UserRegistrationComponent{

constructor(private router:Router){}

  ngOnInit() {

    

    if(localStorage.getItem('token') !=null)
    this.router.navigateByUrl('home');

    this.router.navigate(['/login']);
  }

}
