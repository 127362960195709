import { Component, OnInit } from '@angular/core';
import { UserService } from '../../shared/user.service';
import { NgModel, NgForm } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-userinfo',
  templateUrl: './userinfo.component.html',
  styleUrls: ['./../homebase.component.css']
})
export class UserinfoComponent implements OnInit {

  person_icon = 'src/app/images/person_icon.png'; 


  userDetails;
  constructor(private service:UserService, private router:Router) { }

  ngOnInit() {

    
this.service.getUserProfile().subscribe(
  res =>{
    this.userDetails = res;
    
    
    if(this.userDetails[0]['profileimage'] == "" || this.userDetails[0]['profileimage'] == null )
    {
      this.userDetails[0]['profileimage'] = this.person_icon;
    }



  },
  err =>{
    console.log(err);

  },
);
  }

  

  onSubmituserprofile(gotouserprofile: NgForm) { 
   
    this.router.navigateByUrl(this.userDetails[0]['userid'] +'/profile'); 
    

  }

}
