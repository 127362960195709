import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GroupService } from '../../group.service';
                        

@Component({
  selector: 'app-cmcategories',
  templateUrl: './cmcategories.component.html',
  styleUrls: ['./../homebase.component.css']
})
export class CmcategoriesComponent implements OnInit {
  listofgroups;
  group_icon ="src/app/images/shapes/group.png";

  constructor(private router:Router,private groupservice :GroupService) { }

  ngOnInit() {



    this.groupservice.getlistofgroups().subscribe(
      res => {
        this.listofgroups = res;
    
        var idx_img = 0;

        for (var i of this.listofgroups)
        {
          if(this.listofgroups[idx_img]['image'] == null || this.listofgroups[idx_img]['image'] == "" )
          {
          
            this.listofgroups[idx_img]['image'] = this.group_icon;
          }

          idx_img = idx_img +1;
        }
      },
      err => {}

    );



  }//////////////////////////// Last braket for ngoninit

  search_redirect(uri:string) 
  {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate([uri]));
  }

gotopostboard()
{
  this.router.navigateByUrl('/postboard');
}



goto_group(grp_id: number)
{
 
 this.router.navigateByUrl("group/" + grp_id + "/home");   // Works with lazy loads.
 
}




}
