import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlBar } from './control-bar/control-bar.component';
import { MenurequestComponent } from './menurequest/menurequest.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { HamburgerComponent } from './hamburger/hamburger.component';
import {TimeAgoPipe} from 'time-ago-pipe';
import { SmallhomemenubottomComponent } from './homebase/smallhomemenubottom/smallhomemenubottom.component';


import { ReactiveFormsModule } from '@angular/forms';

//All the componets theat should be lazy loaded, should not be here as they will load when app loads. or so i think

@NgModule({
  imports: [
    CommonModule,ReactiveFormsModule
  ],
  declarations: [ControlBar,MenurequestComponent,HamburgerComponent,NotificationsComponent,TimeAgoPipe
  ,SmallhomemenubottomComponent
  ],
  exports:[ControlBar,
    MenurequestComponent,
    HamburgerComponent,
    NotificationsComponent,
    TimeAgoPipe
    ,SmallhomemenubottomComponent
    
    
    ,ReactiveFormsModule]
})
export class SharedModule { }  
