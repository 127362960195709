import { Component, OnInit } from '@angular/core';
import { ProductsService } from '../products.service';
import { Router } from '@angular/router';
import { PlatformService } from '../shared/platform.service';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements OnInit {

  prices;
  sub1;
  sub3;
  sub6;
  cart_items;
  show_cart_flag = "N";
  Seeplans ='Y';
  add_product;
  states_list=[];
  count_states_list;
  alert_box_show = 'N';
alert_box_text="";
loaded_state="";
updated_cart_or_state ="N";

  constructor(private productsservice: ProductsService,private router: Router,private platformservice : PlatformService) { }

  ngOnInit() {

this.productsservice.get_prices().subscribe(
  res => {
    this.prices = res;
var i = 0;
    for (var p of this.prices)
{
  if (this.prices[i]['itemOrder'] == 1)
  {
    this.sub1 = this.prices[i]['list_item'];
  }
  else if (this.prices[i]['itemOrder'] == 2)
  {this.sub3 = this.prices[i]['list_item'];}
  else if (this.prices[i]['itemOrder'] == 3)
  {this.sub6 = this.prices[i]['list_item'];}
i = i +1;

}

this.productsservice.check_if_cart().subscribe(
  res => {

    this.cart_items = res;
    
    if(this.cart_items != null && this.cart_items != "")
    {

      if(this.cart_items.length >0)
      {this.show_cart_flag = "Y";}
      this.cart_items = res;

      if(this.show_cart_flag == "Y")
        {
          this.productsservice.get_state().subscribe(
            res => {
              (<HTMLInputElement>document.getElementById("statebill")).value = res;
              this.loaded_state =res;
            },
            err =>{},
          )
        }
    }

  },
  err => {},
)

  },
  err => {},
)




this.platformservice.get_loadcountriesstates().subscribe(
  res => {
this.count_states_list = res;

 for(var x of this.count_states_list)
 {
  if(x['list_type'] == '6')
  {
this.states_list.push(x);
  }
 }
  },
  err => {},
)





  }/// end of ngoninit

add_sub_to_cart(product)
{
  this.updated_cart_or_state = "Y";
  this.Seeplans = 'N';
  this.show_cart_flag = "Y";
  this.productsservice.add_cart_item(product).subscribe(
    res => {

      this.add_product = res;

      
this.cart_items.push(this.add_product);
      

      
    },
    err => {},
  )

}


showplans()
{  this.Seeplans = 'Y';}



remove_cart_item(prd,idx)
{
  this.productsservice.remove_cart_item(prd).subscribe(
    res => {

      if (idx > -1) {
        this.cart_items.splice(idx, 1);
     }
this.updated_cart_or_state = "Y";
    },
    err => {},
  )
}

gohome_redirect(uri:string) 
  {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate([uri])); 
    
  }

gotocheckout()
{

// add state to cart here

if((<HTMLInputElement>document.getElementById("statebill")).value.toString() == "")
{
  
  this.alert_box_text = "Please select your state";
  this.alert_box_show = 'Y';

}
else{
  this.productsservice.add_state((<HTMLInputElement>document.getElementById("statebill")).value.toString()).subscribe(
    res => {
  if(res =="Y")
  {
var latest_state =(<HTMLInputElement>document.getElementById("statebill")).value.toString();

//check if order is older than 5 hours, if so remove. If change flag is Y, also remove
if(this.loaded_state.toString() != latest_state )
{
  this.updated_cart_or_state = "Y";
}

this.productsservice.update_order_status(this.updated_cart_or_state).subscribe(
  res =>{

    this.router.navigateByUrl("checkout");
  },
  err => {},
)

   
  }
  
    },
    err => {
      console.log("Error with")
    },
  )

}
}

ok_alert()
{this.alert_box_show = 'N'
this.alert_box_text = "";
}




}
