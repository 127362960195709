import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { PlatformService } from './shared/platform.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {


  constructor( private http:HttpClient, private platforbaseur: PlatformService) { }

 // readonly BaseURI = 'http://localhost:44385/api/';
 readonly BaseURI =this.platforbaseur.BaseURI; 

getmainfeed(x)
{
  
  return this.http.post(this.BaseURI+'Mainfeed/getmainfeed/', x);
}

checkfoundcomplemate()
{
  var pack ={User_id:""}
  return this.http.post(this.BaseURI+'Mainfeed/checkfoundcomplemate/', pack, {responseType: 'text'});
}


foundcomplemate(f)
{

  var pack ={Foundcomplemateby:f}
  return this.http.post(this.BaseURI+'Mainfeed/foundcomplemate/', pack, {responseType: 'text'});
}

getquestion()
{

  var pack ={List_item:"q"}

  return this.http.post(this.BaseURI+'Mainfeed/getquestion/', pack, {responseType: 'text'});
}

get_sys_message()
{ 
  var pack = {For_user:"dummy"}
  return this.http.post(this.BaseURI+'Mainfeed/get_sys_messages/', pack)
}

act_sys_note(id)
{
  var pack = {Id:id}
  return this.http.post(this.BaseURI+'Mainfeed/okay_sys_message/', pack, {responseType: 'text'});
}


submitemailcode(c)
{
  var pack = {Last_code_user_sent:c}
  return this.http.post(this.BaseURI+'Mainfeed/submitemailcode/', pack, {responseType: 'text'});
}

checkifemailverified()
{
  var pack = {User_db_id:"dummy"}
  return this.http.post(this.BaseURI+'Mainfeed/checkifemailverified/', pack, {responseType: 'text'});
}


}
