import { Injectable } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { createTokenForExternalReference } from '@angular/compiler/src/identifiers';
import { PlatformService } from './shared/platform.service';


@Injectable({
  providedIn: 'root'
})
export class GroupService {

  constructor(private fb:FormBuilder, private http:HttpClient, private platforbaseur: PlatformService) { }
 
  //readonly BaseURI = 'http://localhost:44385/api/'; 
  readonly BaseURI =this.platforbaseur.BaseURI;  


  getgroupheader(groupid)
  {
    return this.http.post(this.BaseURI+'groups/groupheader/', groupid) 
  }


  PostformModel = this.fb.group({
    PostText :['',[Validators.required]]
    ,Topic:['',[]]
    });




    getgrouppost(groupid)
    {
      return this.http.post(this.BaseURI+'groups/getgrouppost/', groupid)

    }


    makepost(groupid,image){
      var postbody = {
    
        Grp_id : groupid,
        Post_text : this.PostformModel.value.PostText,
 
        Topic:this.PostformModel.value.Topic
        ,Image : image
    
      };
    return this.http.post(this.BaseURI+'groups/grouppost/', postbody);
    
    }




    sendaction(postactionid,Commentid,replyid,theaction, tbl_gen)
    {
      var actionbody={
        Post_id : postactionid,
        Comment_id : Commentid,
        Parent_comment_id :replyid,
       // ActionDate
      //  ActionTimezon
        Action_clicked :theaction,
        Tbl_gen: tbl_gen
      }
      return this.http.post(this.BaseURI+'groups/grppostaction/', actionbody, {responseType: 'text'});
     
    
    }
    
    CommentformModel = this.fb.group({
      CommentText :['',[Validators.required]]
     ,Commentidactive :['',[Validators.min(1)]]
    })
    

    makecomment(post_id,commenttext,tbl_gen)
    {
    
    var commentbody={
      Post_id : post_id,
      Comment_text : commenttext,
      Tbl_gen: tbl_gen
        
    };
  
    return this.http.post(this.BaseURI+'groups/groupcomment/', commentbody, {responseType: 'text'});
        
    }
    

    

//Get single post for comments
getsinglepost(singlepostid)
  {
    
    return this.http.post(this.BaseURI+'groups/getsinglegpost/', singlepostid)
  }



//Get  comments for single post
getcomments(postforcomments)
{
  return this.http.post(this.BaseURI+'groups/getgrpcomments/', postforcomments)
}
 

CommentformModelonC = this.fb.group({
  CommentTextonC :['',[Validators.required]]
 ,CommentidactiveonC :['',[Validators.min(1)]]
})


get_group_privacy(groupid)
{
  var grp= {Grp_id : groupid}
  return this.http.post(this.BaseURI+'groups/get_group_privacy/', grp,  {responseType: 'text'} )
}




ReplyformModel = this.fb.group({
  ReplyText:['',[Validators.required]]
})

makereply(commentID,replytext,image,tbl_gen)
{
  var replybody ={
    Parent_comment_id :commentID,
    Comment_text : replytext,
    Image: image,
    Tbl_gen : tbl_gen
  }
//need to call api after this
return this.http.post(this.BaseURI+'groups/postgrpreply/', replybody);
   

} 

 

makecommentonC(post_id,image, tbl_gen)
{

var commentbodyonC={
  Post_id : post_id,
  Comment_text : this.CommentformModelonC.value.CommentTextonC   
  ,Image: image,
  Tbl_gen : tbl_gen
};

return this.http.post(this.BaseURI+'groups/groupcomment/', commentbodyonC);
    
}





getmorereplies(post_id,comment_id, count,tbl_gen)
{

var comment_info = {
  Post_id:post_id,
  Comment_id :comment_id,
  Visibility: count,
  Tbl_gen:tbl_gen
}
return this.http.post(this.BaseURI+'groups/getmorereplies/', comment_info)
}




getlistofgroups()
{
  var Useaspauth={
    Post_id:"useraspauth"
  }

  return this.http.post(this.BaseURI+'groups/getgrouplist/', Useaspauth);

}




getcatlist()
{

  var gender={Gender:9}

  return this.http.post(this.BaseURI+'groups/getcatlist/', gender);
}






ismemeber(id)
{
  var key={Grp_id:id}
  return this.http.post(this.BaseURI+'groups/ismember/', key, {responseType: 'text'});
}


geteditgroup(gid)
{
  var groupitems={Grp_id:gid}

  return this.http.post(this.BaseURI+'edit/editgroup/', groupitems);
  

}



//EditGroupModel
EditGroupModel = this.fb.group({
  Groupname:['',[Validators.required,Validators.min(3)]]
  ,Listed:['',[]]
  ,Permission:['',[]]
  ,Summary:['',[Validators.required,Validators.min(5)]]
  ,About:['',[]]
  ,Website:['',[]]
  ,Approval:['',[]] 
  
})


savegroupedit(grpimage, bannerimage, grpid)
{
var grp_edits ={
  Grp_id :grpid,
  Grp_name : this.EditGroupModel.value.Groupname,
  Listed : this.EditGroupModel.value.Listed,
  Permission : this.EditGroupModel.value.Permission,
  Summary : this.EditGroupModel.value.Summary,
  About : this.EditGroupModel.value.About,
  Website : this.EditGroupModel.value.Website,
  Approveusers : this.EditGroupModel.value.Approval,
  Image : grpimage,
  Bannerimage : bannerimage


}

  return this.http.post(this.BaseURI+'edit/savegroupedit/', grp_edits,  {responseType: 'text'});


}


getuserinfo()
{
  var dummy ={Sex:"0"}
  return this.http.post(this.BaseURI+'groups/getuserinfo/', dummy)
}

isgroupowner(grpid)
{
  var getgrpid ={Grp_id:grpid}
  return this.http.post(this.BaseURI+'groups/isgroupowner/', getgrpid)
}



getreportlist()
{ var canget ={Gender:3}
  return this.http.post(this.BaseURI+'edit/reporttypes/', canget)
}

deletegroup(grp_id)
{ var thisgroup ={Report_id:grp_id
,Other_details :this.Cancelgroup.value.Why
}
  return this.http.post(this.BaseURI+'edit/deletegroup/', thisgroup,  {responseType: 'text'})
}





Cancelgroup= this.fb.group({
  Why:['',[Validators.required]]
})


//ReportModel
ReportModel = this.fb.group({
  Report_type:['',[Validators.required]]
 ,Reporttext:['',[]]

})

sendreport(contentid,contenttype,tbl_gen)
{ var reportpack ={
  Content_id:contentid,
  Report_type: this.ReportModel.value.Report_type,
  Content_type: contenttype,
  Other_details: this.ReportModel.value.Reporttext,
  Tbl_gen: tbl_gen
}


  return this.http.post(this.BaseURI+'edit/submitreport/', reportpack,  {responseType: 'text'})
}






editpost(postid,text, tbl_gen)
{
 var editwith={
  Post_id : postid,
  Post_text : text,
  Image: "",
  Tbl_gen: tbl_gen
 } 

  return this.http.post(this.BaseURI+'edit/grpeditpost/', editwith,  {responseType: 'text'})
}




deletepost(postid, tbl_gen)
{
  var deletethis ={Post_id:postid, Tbl_gen: tbl_gen} 
  return this.http.post(this.BaseURI+'edit/grpdeletepost/', deletethis,  {responseType: 'text'})
}



editcomment(commentid,updatetext,image, tbl_gen)
{
  
  var editcomwith={
    Comment_id:commentid,
    Comment_text:updatetext,
    Image:image,
    Tbl_gen:tbl_gen

   } 
   
  return this.http.post(this.BaseURI+'edit/grpeditcomment/', editcomwith,  {responseType: 'text'})
}


deletecomment(Commentid,tbl_gen)
{
  var deletethiscom ={Comment_id:Commentid, Tbl_gen:tbl_gen} 
  return this.http.post(this.BaseURI+'edit/grpdeletecomment/', deletethiscom,  {responseType: 'text'})
}



//deletereply
deletereply(Commentid,tbl_gen)
{
  var deletethiscom ={Comment_id:Commentid, Tbl_gen : tbl_gen} 
  return this.http.post(this.BaseURI+'edit/grpdeletereply/', deletethiscom,  {responseType: 'text'})
}



//sharepost
sharepost(postid, tbl_gen)
{
  var theshare={
    
        Post_id: postid , Tbl_gen : tbl_gen
  }
  return this.http.post(this.BaseURI+'groups/sharepost/', theshare, {responseType: 'text'});
 

}



getpostboard()
{
  var Useaspauth={
    Post_id:"useraspauth"
  }
  return this.http.post(this.BaseURI+'postboard/getpostboard/', Useaspauth);
}



filter_cats(topic)
{
  var thetopic ={Topic: topic}

  return this.http.post(this.BaseURI+'postboard/filter_cats/', thetopic);

}


removefromgroup(groupid, userid_r)
{
  var toremove = {Grp_id:groupid, Member_db_id :userid_r
}


  return this.http.post(this.BaseURI+'groups/removefromgroup/', toremove, {responseType: 'text'});
}



seemembers(grpid,took)
{
  var thegroup ={Grp_id: grpid,
    Status:took
  }

  return this.http.post(this.BaseURI+'groups/see_members/', thegroup);

}







}
