import { Component, OnInit } from '@angular/core';
import { DatingService } from '../dating.service';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms'
import { ConnectionsService } from '../connections.service';
import { PlatformService } from '../shared/platform.service';



@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  themates=[];
  previous_search;
  when_serach;
  notifications;
  isresults = 1;
  singletownflag;
  offset = new Date().getTimezoneOffset(); 
  genderofuser="";
  search_filter_mobile_div ='N';
  heart_notes_div = 'N';
  notes_count=0;
  daters_count=0;
  no_acts='N';

act_or_match_flag="A";
lastest_note_time= '';
datingsearchloading ='N';
loading_imgs = 'src/app/images/shapes/loading_img_01v3.png';
notes_loading='N';
more_note_matches='N';
show_if_dating_message = 'Y';


  constructor(private datingservices :DatingService,private router: Router,private fb:FormBuilder,private connectservice : ConnectionsService,private getstanderprofile : PlatformService) { }

  ngOnInit() {
    this.datingservices.Servicemodel.reset();
    

    this.connectservice.checksingletown("blah").subscribe(
      res => {
    
        this.singletownflag = res;

        if(this.singletownflag == 'M')
        {
          // go to home page this person is married
          this.godatingprofile_redirect("home/");
        }
        else if (this.singletownflag == 'N')
        {
          // go to dating sign up page
          this.godatingprofile_redirect("datingsetup/");
        }
      },
      err => {},
    )

    this.datingservices.user_last_search("blah").subscribe(   
      res => {

        this.previous_search = res;

        if(this.previous_search != null && this.previous_search != '')
        {

          this.datingservices.Servicemodel = this.fb.group({
            zipcode:[this.previous_search[0]["zipcode"],[]]
           ,radius:[this.previous_search[0]["mile_radius"],[]]
           ,agefrom:[this.previous_search[0]["age_from"],[]]
           ,ageto:[this.previous_search[0]["age_to"],[]]
           ,religion:[this.previous_search[0]["religion"],[]]
           ,children:[this.previous_search[0]["max_children"],[]]
          
          });
          this.when_serach ="L";
  
          this.onSearchsubmit(1);
        }

       

      },
      err => {console.log("Error getting past search")},

    )


this.notes_loading = "Y";
    this.datingservices.notifications("N",0).subscribe(
      res => {
        this.notifications = res;

        this.notes_loading = "N";
if(res == ""  || res == null)
  { this.notes_loading = "N";}

        for (var h of this.notifications)
          {
            

        if (this.notifications.length > 0)
        {

          var notedate = new Date(h['datetime']);
          var noteoffsettime = new Date(notedate.getTime() + this.offset * -60 *1000 ) 
          h['datetime']= noteoffsettime;

          this.lastest_note_time = this.notifications[0]['datetime'];
        }

         this.notes_count = this.notes_count +1;
          var today = new Date();
          var birthdate = new Date(h["dob"]);
          var age = today.getFullYear() - birthdate.getFullYear();
  
          var m = today.getMonth() - birthdate.getMonth();
          var d = today.getDay() - birthdate.getDay();
  

      


       if(m > 0)
       { h["dob"] = age; }
       else{
        if(d > 0)
        { h["dob"] = age; }
        else{h["dob"] = age -1;}
       }
      }
        
      },
      err => {},
    )



    this.getstanderprofile.getpname().subscribe(
      res => {
        this.genderofuser =res[0]['gender'];
      },
      err => {console.log("Not pname");},
    );


  }////////////////////////////////////// last braket of ngoninit

  godatingprofile_redirect(uri:string) 
  {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate([uri]));
  }

  change_loading_img()
  {
    if (this.loading_imgs == "src/app/images/shapes/loading_img_01v3.png")
    {
      
    this.loading_imgs = "src/app/images/shapes/loading_img_02v3.png"
    }
    else{
    
    this.loading_imgs = "src/app/images/shapes/loading_img_01v3.png"
    }
  }
  

  onSearchsubmit(onSearchsubmit)
  {
    this.datingsearchloading = 'Y';
    setInterval(()=> { this.change_loading_img() }, 1 * 500);

if(onSearchsubmit != 2)
{
  this.daters_count = 0;
  this.themates = [];
}

    if(this.when_serach != "L")
    {
      this.when_serach = "S";
    }

var load_mates;


this.datingservices.getmates(this.when_serach,this.daters_count).subscribe( 
res =>{
  
 // this.themates = res;
  load_mates = res;
  this.when_serach ="";



for (var x of load_mates)
      {
        this.daters_count = this.daters_count + 1 ;
        var today = new Date();
        var birthdate = new Date(x["dob"]);
        var age = today.getFullYear() - birthdate.getFullYear();

        var m = today.getMonth() - birthdate.getMonth();
        var d = today.getDay() - birthdate.getDay();

     if(m > 0)
     { x["dob"] = age; }
     else{
      if(d > 0)
      { x["dob"] = age; }
      else{x["dob"] = age -1;}
     }

    x["to_be_good_gender"] = x["to_be_good_gender"].substring(0,190);

    var postdate = new Date(x['last_login']);
    var offsettime = new Date(postdate.getTime() + this.offset * -60 *1000 )
    x['last_login']= offsettime;

    this.themates.push(x);
    this.datingsearchloading = 'N'; 
      }

      if(load_mates.length == 0)
      { this.datingsearchloading = 'N'; }
     

  if(load_mates.length == 0)
  {this.isresults=0; }
  else{this.isresults=1;}

console.log(res);
},
err => {

},

)

  }



  gotodateprofile(id,screenname)
  {


    this.router.navigateByUrl("stprofile/" + id + "/" + screenname); 
    
  }


  search_filter_mobile()
  {
    if(this.search_filter_mobile_div == 'N')
    {
      this.search_filter_mobile_div = 'Y';
    }
    else if (this.search_filter_mobile_div == 'Y')
    {
      this.search_filter_mobile_div = 'N';
    }
  }
  
  heart_notes()
  {
    if(this.heart_notes_div == 'N')
    {
      this.heart_notes_div = 'Y';
    }
    else if (this.heart_notes_div == 'Y')
    {
      this.heart_notes_div = 'N';
    }
  }


  get_matches()
  {

    this.notifications = null;
    this.notes_loading = "Y";

    if(this.act_or_match_flag == "A")
    {
      this.act_or_match_flag ="M";
      this.notes_count = 0;

      this.datingservices.notifications("M",0).subscribe(
        res => {
          this.notes_loading = "N";
          if(res == ""  || res == null)
            { this.notes_loading = "N";}


          this.notifications = res;
          
          for (var h of this.notifications)
          { this.notes_count = this.notes_count +1;
            var today = new Date();
            var birthdate = new Date(h["dob"]);
            var age = today.getFullYear() - birthdate.getFullYear();
    
            var m = today.getMonth() - birthdate.getMonth();
            var d = today.getDay() - birthdate.getDay();
    
            
         if(m > 0)
         { h["dob"] = age; }
         else{
          if(d > 0)
          { h["dob"] = age; }
          else{h["dob"] = age -1;}
         }
        }
          
        },
        err => {},
      )
  

    }

  }

  get_activity()
  {
    this.notifications = null;
    this.notes_loading = "Y";

    if(this.act_or_match_flag == "M")
    {
      this.act_or_match_flag ="A";
      this.notes_count = 0;

        this.datingservices.notifications("N",0).subscribe(
      res => {
        this.notes_loading = "N";
        if(res == ""  || res == null)
          { this.notes_loading = "N";}



        this.notifications = res;
     

        for (var h of this.notifications)
        { this.notes_count = this.notes_count +1;
          var today = new Date();
          var birthdate = new Date(h["dob"]);
          var age = today.getFullYear() - birthdate.getFullYear();
  
          var m = today.getMonth() - birthdate.getMonth();
          var d = today.getDay() - birthdate.getDay();
  

          var postdate = new Date(h['datetime']);
          var offsettime = new Date(postdate.getTime() + this.offset * -60 *1000 ) 
          h['datetime']= offsettime;

       if(m > 0)
       { h["dob"] = age; }
       else{
        if(d > 0)
        { h["dob"] = age; }
        else{h["dob"] = age -1;}
       }
      }
        
      },
      err => {},
    )


    }

  }

  more_actormat()
  {
this.more_note_matches = 'Y';

    var more_hearts;

  var actormat = "";

    if(this.act_or_match_flag == 'A')
    {actormat = 'N';}
    else if (this.act_or_match_flag == 'M')
    {actormat = 'M';}


 this.datingservices.notifications(actormat,this.notes_count).subscribe(
      res => {
        this.more_note_matches = 'N';
        if(res == null || res == "")
          {
            this.more_note_matches = 'N';
          }

         more_hearts = res;

         if (more_hearts == "" || more_hearts == null)
         {
          this.no_acts = 'Y';
         }
         else { this.no_acts = 'N';}

        for (var h of more_hearts)
        { this.notes_count = this.notes_count +1;
          var today = new Date();
          var birthdate = new Date(h["dob"]);
          var age = today.getFullYear() - birthdate.getFullYear();
  
          var m = today.getMonth() - birthdate.getMonth();
          var d = today.getDay() - birthdate.getDay();
  

          
       if(m > 0)
       { h["dob"] = age; }
       else{
        if(d > 0)
        { h["dob"] = age; }
        else{h["dob"] = age -1;}
       }

       this.notifications.push(h);

      }
        
      },
      err => {},
    )
  }

  get_more_daters()
  {

  }

  ok_alert_about_dating()
  {
    this.show_if_dating_message = 'N';
  }

}
