import { Component } from '@angular/core';
import { NgModel, NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { post } from 'selenium-webdriver/http';
import { Router } from '@angular/router';
import { UserService } from '../shared/user.service';
import { HomeService } from '../home.service';
import { ChatService } from '../chat.service';



@Component({
  selector: 'homebase',
  templateUrl: './homebase.component.html',
  styleUrls: ['./homebase.component.css']
})

export class Homebase {
userDetails;
mobile: boolean;
prelaunch ='N'
ismobile="";
ipaddress;
load_main ='N';



  constructor(private http: HttpClient,private router:Router, private service:UserService,private homeservice: HomeService,private chatservice : ChatService) { } 

  
ngOnInit(){

this.service.app_launch_check().subscribe(
  res => {
    this.prelaunch = res;
  },
  err => {},

)

this.getIPAddress();

this.service.getUserProfile().subscribe(
  res =>{
    this.userDetails = res;
  },
  err =>{
    console.log(err);
  },
);


this.homeservice.checkifemailverified().subscribe(
  res => {    
    if(res == 'Ok')
    {
// Do nothing
    }
    else if (res == 'No') 
    {
      this.router.navigate(['/emailverification']);
   


    }
  },
  err => {},
)


//load_pre_load_convos
this.chatservice.load_pre_load_convos().subscribe(
res => {
  console.log(res);
},
err => {},
)




/*
if (window.screen.width === 360) { // 768px portrait
  this.mobile = true;}

*/

this.sendloginfo();

}


gosearch_redirect(uri:string) 
{
  this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
  this.router.navigate([uri]));
}




ngAfterViewInit()
{
  this.load_main ='Y';
}


  isExpanded = false;

  collapse() {
    this.isExpanded = false;
  }
  // Add something add something else


  



  toggle() {
    this.isExpanded = !this.isExpanded;
  }



  sendloginfo()
  {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){ 
      this.ismobile="Y";
    }
    else if(!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
    {
      this.ismobile="N";
    }
    else{this.ismobile="E";}

    const { detect } = require('detect-browser');
    const browser = detect();

 
    this.service.sendloginfo(this.ipaddress,browser.name,browser.version,browser.os,this.ismobile).subscribe(
      res => {
        console.log(res);
      },
      err => {
        console.log(err);
      },
    )


  } 

  getIPAddress()
  {
    this.http.get("https://api.ipify.org/?format=json").subscribe((res:any)=>{
      this.ipaddress = res.ip;
    });
  }




}
