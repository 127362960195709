import { Component, OnInit } from '@angular/core';
import { ConnectionsService } from '../connections.service';
import { Router } from '@angular/router';



@Component({
  selector: 'app-menurequest',
  templateUrl: './menurequest.component.html',
  styleUrls: ['./menurequest.component.css']
})
export class MenurequestComponent implements OnInit {
  requestnotes;
  grpreqestones;
  person_icon = 'src/app/images/person_icon.png';
  group_icon = 'src/app/images/group_icon.png';
  listoffemalestochat;
  isnewfemalechat = "N";

  constructor(private connectservice : ConnectionsService,private router:Router) { }

  ngOnInit() {

this.connectservice.getrequest().subscribe(
  res => {
this.requestnotes = res;


for (var x of this.requestnotes)
    {
      if(x['profileimage'] ==  "" || x['profileimage'] ==  null)
          {
            x['profileimage'] = this.person_icon;
          }

    }


 
  },
  err => {

  },
)



this.connectservice.getgrouprequest().subscribe(
  res => {

this.grpreqestones = res;

for (var x of this.grpreqestones)
    {
      if(x['profileimage'] ==  "" || x['profileimage'] ==  null)
          {
            x['profileimage'] = this.person_icon;
          }

          if(x['grp_image'] ==  "" || x['grp_image'] ==  null)
          {
            x['grp_image'] = this.group_icon;
          }

    }



  },
  err => {

  },
)

//getnewdatecontact
this.connectservice.getnewdtchat().subscribe(
  res => {
this.listoffemalestochat = res; 


if(res != null && res != "")
{this.isnewfemalechat= "Y";}

  },
  err => {
    console.log("Error getting new date chat connect");
  },

)




  }//////////////////////////// Last ngoninit braket

  redirectTo(uri:string){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate([uri]));
 }


 requestall()
 {this.router.navigateByUrl('/friends');}
 grouprequestall()
 {this.router.navigateByUrl('/groups');}

  gotopreview(useridp)
  {
 
    //this.redirectTo("preview/" + useridp);  
    //this.router.navigateByUrl("preview/" + useridp); 

    this.connectservice.ssuserprofile(useridp[0]).subscribe(
      res => {
        var ssgoorno = res;
  
        if(ssgoorno == 'Y')
        {
          this.router.navigateByUrl(useridp + "/profile");
        }
        else{
          this.router.navigateByUrl("preview/" + useridp );
        }
  
  
  
      },
      err => {},
    )
  } 



  respond(selection,requestor,type, index,grp_id,type_of_request) 
  {

 
if(type_of_request != '25' )
{


    if(type == "1" || type == "0")
    {this.requestnotes.splice(index,1);}
    else if (type == "2")
    {this.grpreqestones.splice(index,1);} 
 
    
this.connectservice.giveresponse(selection,requestor,type,grp_id).subscribe(
  res =>{
    console.log("Response made")
  },
  err => {

  },
)
  }
  else if (type_of_request == '25') 
  {

    
    this.connectservice.ferequest(requestor,selection).subscribe(
      res => {
     
        this.requestnotes.splice(index,1);
      },
      err => {
        console.log("There was issue with responding to if your the father or elder")
      },
    )

  }

  }


  sayok(x,index) 
  {
    this.connectservice.newfemalechatack(x).subscribe(
      res => {
//listoffemalestochat
if(index > -1)
{
  this.listoffemalestochat.splice(index, 1)
}

      },
      err => {},
    )
  }

  gotodtprofile(dt_id, screennameid)
  {
    this.router.navigateByUrl("stprofile/" + dt_id + "/" + screennameid); 
  }

}
