import { Injectable } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { Binary, identifierModuleUrl } from '@angular/compiler';
import { PlatformService } from './shared/platform.service';

@Injectable({
  providedIn: 'root'
})
export class DatingService {

  constructor(private fb:FormBuilder, private http:HttpClient, private platforbaseur: PlatformService) { }

 // readonly BaseURI = 'http://localhost:44385/api/';
 readonly BaseURI =this.platforbaseur.BaseURI; 




  DatingRegModel = this.fb.group({
    screenname :['',[Validators.required,Validators.minLength(3)]]
  //  ,birthdate:['',[]]
    ,City:['',[]]
    ,State:['',[]]
    ,Country:['',[Validators.required]]
    ,Children:['',[Validators.required]]
    ,Religion:['',[Validators.required]]
    ,Religionseriousness:['',[]]
    ,society:['',[]]
    ,Homeschool_desire:['',[]]
    ,goodman:['',[]]
    ,goodwoman:['',[]] 
    ,confident:['',[]]
    
    ,Zipcode:['',[Validators.required,Validators.minLength(5)]]
    ,bio:['',[Validators.required,Validators.minLength(5)]]
    });

    createdatingprofile(good_gender)
    {
      var goodfit ={
        Screenname: this.DatingRegModel.value.screenname,

        //DOB: this.DatingRegModel.value.birthdate,
        City: this.DatingRegModel.value.City,
        State: this.DatingRegModel.value.State,
        Country: this.DatingRegModel.value.Country,
        Zipcode:this.DatingRegModel.value.Zipcode,
        Children: this.DatingRegModel.value.Children,
        Religion: this.DatingRegModel.value.Religion,
        Religion_seriousness: this.DatingRegModel.value.Religionseriousness,
        Contribute_to_society: this.DatingRegModel.value.society,
        Homeschool_desire: this.DatingRegModel.value.Homeschool_desire,
        To_be_good_gender:good_gender,
       // To_be_good_gender: this.DatingRegModel.value.goodman,
      // To_be_good_gende: this.DatingRegModel.value.goodwoman,
      How_confident: this.DatingRegModel.value.confident,
      Bio: this.DatingRegModel.value.bio,
      
      }

      return this.http.post(this.BaseURI+'dating/createdtprofile/', goodfit);
    

    }




    
    DatingFinalSteps = this.fb.group({
      file:['',[]]
      ,mealplate:['',[]]
      ,htto:['',[]]
    });

finalstep(headshot,headtotoe,mealplate)
{

  var finalpack =
  {
    Head_shot : headshot,
    Head_to_toe:headtotoe,
    Meal_plate:mealplate,
   
  }


  return this.http.post(this.BaseURI+'dating/finalstep/', finalpack);
    

}




getprofile(stid,userid)
{

  var datinguser ={
    Dating_header_id : stid,
    Screennameid: userid

  }

  return this.http.post(this.BaseURI+'dating/getprofile/', datinguser);
}



getfinalstepsfields(type)
{
var finalstepfields ={
  Job_or_education : type
  }

  return this.http.post(this.BaseURI+'dating/getfinalstepsfields/', finalstepfields);
}

getworkedu(type,dtid)
{
  var getthisdtinfo = {
    Job_or_education : type,
    Dating_header_id : dtid
  }
  return this.http.post(this.BaseURI+'dating/geteduwork/', getthisdtinfo);
}

WorkSchoolform = this.fb.group({
  workschoolname:['',[Validators.required,Validators.min(3)]]
  ,startmonth:['',[Validators.required]]
  ,endYear:['',[Validators.required]]
  ,endmonth:['',[Validators.required]]
  ,startYear:['',[Validators.required]]
  ,education_or_job_flag:['',[Validators.required]]
  ,degreelevel:['',[]]
  ,Current:['',[]]
  
 
});

addschoolorwork()
{
if(this.WorkSchoolform.value.Current == true)
{this.WorkSchoolform.value.Current = 'Current'}
else {this.WorkSchoolform.value.Current = 'No'}

var varwokshist ={
  Jobtitle_or_school : this.WorkSchoolform.value.workschoolname,
  StartYear : this.WorkSchoolform.value.startYear,
  StartMonth : this.WorkSchoolform.value.startmonth,
  //Details
  EndYear :  this.WorkSchoolform.value.endYear,
  EndMonth : this.WorkSchoolform.value.endmonth,
  Job_or_education:this.WorkSchoolform.value.education_or_job_flag
  ,Degree:this.WorkSchoolform.value.degreelevel
  ,Current:this.WorkSchoolform.value.Current
}


  return this.http.post(this.BaseURI+'dating/addworkorjob/', varwokshist);
}





Servicemodel = this.fb.group({
  zipcode:['',[]]
 ,radius:['',[]]
 ,agefrom:['',[]]
 ,ageto:['',[]]
 ,religion:['',[]]
 ,children:['',[]]

});

getmates(when_search,count)
{
  var selections ={
    Zipcode : this.Servicemodel.value.zipcode,  //char 
    Mile_radius : this.Servicemodel.value.radius, //int
    Age_from :this.Servicemodel.value.agefrom, //int
    Age_to :this.Servicemodel.value.ageto, //int
    Religion : this.Servicemodel.value.religion,  //char
    Max_children : this.Servicemodel.value.children,    //char
    When_search:when_search,
    Search_id:count

  }
  return this.http.post(this.BaseURI+'dating/getmates/', selections);
}


user_last_search(uesrid)
{ 
var user ={User_db_id:uesrid}
  return this.http.post(this.BaseURI+'dating/lastsearch/', user);
}





EditDating= this.fb.group({
  Goodman:['',[]]
 ,Goodwoman:['',[]]
 ,Bio:['',[]]
 ,Status:['',[]]
 ,Society:['',[]]
 ,Confident:['',[]]
 ,Birthdate:['',[]]
 ,Zipcode:['',[]]
 ,City:['',[]]
 ,State:['',[]]
 ,Country:['',[]]
 ,Children:['',[]]
 ,Religion:['',[]]
 ,Religionseriousness:['',[]]
 ,Father_elder:['',[]]
 ,Must_contact_father_elder:['',[]]
 ,Father_elder_status:['',[]]
 ,Homeschool_desire:['',[]]

});


saveedit(gender,img1,img2,img3,dob,fe)
{
  var whatdoesitmeantobeagood ="";
  if(gender == 0) {whatdoesitmeantobeagood = this.EditDating.value.Goodman} else {whatdoesitmeantobeagood = this.EditDating.value.Goodwoman}

var mat_date;
if(this.EditDating.value.Birthdate == null) {mat_date = dob}else {mat_date = this.EditDating.value.Birthdate}


if(this.EditDating.value.Must_contact_father_elder.value == 1)
{this.EditDating.value.Must_contact_father_elder.value = "1"; }
if(this.EditDating.value.Must_contact_father_elder.value == 2)
{this.EditDating.value.Must_contact_father_elder.value = "2"; }

var datingprofile={
  DOB:mat_date,
  Zipcode: this.EditDating.value.Zipcode,
  City: this.EditDating.value.City,
  State: this.EditDating.value.State,
  Country: this.EditDating.value.Country,
  Religion: this.EditDating.value.Religion,
  Religion_seriousness: this.EditDating.value.Religionseriousness,
  Relationshipstatus: this.EditDating.value.Status,
  Children: this.EditDating.value.Children,
  Contribute_to_society: this.EditDating.value.Society,
  How_confident: this.EditDating.value.Confident,
  To_be_good_gender:whatdoesitmeantobeagood,
  //Head_shot_year: this.EditDating.value.zipcode,
  Meal_plate:img3,
  Bio: this.EditDating.value.Bio,
 Head_shot:img1,
  Head_to_toe :img2,
  Father_elder:fe,
  Must_contact_father_elder:this.EditDating.value.Must_contact_father_elder,
  Homeschool_desire:this.EditDating.value.Homeschool_desire,


}


  return this.http.post(this.BaseURI+'dating/saveedit/', datingprofile,  {responseType: 'text'} )

}



editgetprofile(uesrid)
{ 
var user ={User_db_id:uesrid}
  return this.http.post(this.BaseURI+'dating/editgetprofile/', user);
}

delete_item(item_id)
{
var pack = {Item_id:item_id}

  return this.http.post(this.BaseURI+'dating/deleteitem/', pack,  {responseType: 'text'} )

}



save_image_progress(img_1,img_2,img3)
{
var images = {
  Head_shot:img_1
,Head_to_toe:img_2
,Meal_plate:img3
}

  return this.http.post(this.BaseURI+'dating/saveprogress/', images,  {responseType: 'text'} )

}

getimages()
{
var image_request = {User_db_id:"dummy"}

  return this.http.post(this.BaseURI+'dating/getimages/', image_request);
}

saveximage(picture)
{
  var pack ={Image_add : picture}
  return this.http.post(this.BaseURI+'dating/savexpicure/', pack);
}


getadditionalimages()
{ var pack ={Image_add : "blah"}
  return this.http.post(this.BaseURI+'dating/getotherimages/', pack);
}

getadditionalimages_forprofile(dtid)
{ var pack ={Dating_header_id : dtid}
  return this.http.post(this.BaseURI+'dating/getotherimages_forprofile/', pack);
}





delete_other_picures(itemid)
{ var theid = {Item_id :itemid}
  return this.http.post(this.BaseURI+'dating/deleteotherpicture/', theid,  {responseType: 'text'} )
}




check_dating_active()
{
  var himorher = {User_db_id :"blah"}
  return this.http.post(this.BaseURI+'dating/check_dating_active/', himorher,  {responseType: 'text'} )
}




//send_heart
send_heart(dtid)
{ var sendaction ={User_receive_stid : dtid,
  Action :1
}
  return this.http.post(this.BaseURI+'dating/send_heart/', sendaction,  {responseType: 'text'});
}



check_heart(stid)
{ var check ={User_receive_stid: stid}

  return this.http.post(this.BaseURI+'dating/check_heart/', check,  {responseType: 'text'});
}


notifications(match,count)
{ var note ={Match : match, Action: count}
  return this.http.post(this.BaseURI+'dating/notifications/', note);
}



getuid(stid)
{
  var dateheader={Dating_header_id:stid}
  return this.http.post(this.BaseURI+'dating/getuid/', dateheader,  {responseType: 'text'});
}


get_fathers_elders(name_query,count)
{
  var search_text = {First_name:name_query,
    Trouble_meter: count
  }
  return this.http.post(this.BaseURI+'dating/get_fathers_elders/', search_text, );
}

createorcheck_chatpermission(fe_id,dt_hr_id)
{

 
  var perreq = {Father_elder:fe_id,
    Dating_header_id:dt_hr_id
  }

  return this.http.post(this.BaseURI+'dating/chat_permission/', perreq,); 
}

get_fatherelder_dbid(id)
{
  var whoslooking =
  {
    Id: id
  }
  return this.http.post(this.BaseURI+'dating/get_db_id_of_elder/', whoslooking,  {responseType: 'text'});
}

get_dating_profile_status()
{ 
var note ={Dating_header_id :-1}
  return this.http.post(this.BaseURI+'dating/get_dating_profile_status/', note,);
}

sendreasonfordeletingdating(dtid,reason)
{
  var pack ={
    Content_id:dtid,
   Other_details :reason

  }
  return this.http.post(this.BaseURI+'dating/sendreasonfordeletingdating/', pack,);
}

deletedating(dtid)
{
  var pack ={Dating_header_id:dtid}
  return this.http.post(this.BaseURI+'dating/deletedating/', pack, {responseType: 'text'} );
}

checkactive(dtid)
{

  var pack ={Dating_header_id:dtid}
  return this.http.post(this.BaseURI+'dating/checkactive/', pack, {responseType: 'text'});
}


get_profile_sub(profile_dt_id)
{

  var pack ={Dating_header_id:profile_dt_id}

  return this.http.post(this.BaseURI+'dating/get_profile_sub/', pack, {responseType: 'text'});
}


check_match(thepagesstid)
{

  var pack ={Dating_header_id:thepagesstid}

  return this.http.post(this.BaseURI+'dating/check_match/', pack, {responseType: 'text'});
}


userchecksubstatus()
{

  var pack ={Dating_header_id:0}

  return this.http.post(this.BaseURI+'dating/userchecksubstatus/', pack, {responseType: 'text'});
}



canchat(dhid)
{

  var pack ={Dating_header_id:dhid}

  return this.http.post(this.BaseURI+'dating/canchat/', pack, {responseType: 'text'});
}


get_dating_screenname(dt_id)
{ 
var note ={Dating_header_id :dt_id}
  return this.http.post(this.BaseURI+'dating/get_dating_screenname/', note,);
}

check_is_current_fe(dt_id)
{
 
  var note ={Dating_header_id :dt_id}
  return this.http.post(this.BaseURI+'dating/check_is_current_fe/', note,{responseType: 'text'});
}

}
