import { Injectable, EventEmitter } from '@angular/core';
import * as signalR from '@aspnet/signalr';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';  
import { Message } from '../models/message';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { convertActionBinding } from '@angular/compiler/src/compiler_util/expression_converter';
import { PlatformService } from './shared/platform.service';

@Injectable({
  providedIn: 'root' 
})
export class ChatService {
  static removefromhub(convoid: any) {
    throw new Error('Method not implemented.');
  }

   
  
  constructor(private fb:FormBuilder, private http:HttpClient, private platforbaseur: PlatformService) {  
  }  
  

 // readonly BaseURI = 'http://localhost:44385/api/';
 readonly BaseURI =this.platforbaseur.BaseURI; 


//models
Messagemodel = this.fb.group({
  MessageText :['',[Validators.required, Validators.min(1)]]
  
  });


 

  finduser(userid)
  {
    return this.http.post(this.BaseURI+'conversations/usermessages/', userid)

  }
  
  getothername(userid)
  { 

    return this.http.post(this.BaseURI+'conversations/getothername/', userid)

  }


  getconversations()
    {
      var dummy = {Conversation_id : -1}
      return this.http.post(this.BaseURI+'conversations/getconvos/', dummy)

    }


    createconvo(otherid)
    {
       var startmessaging = {
        Sender_id :otherid,
        Message_text : this.Messagemodel.value.MessageText   
       }
return this.http.post(this.BaseURI+'conversations/startconvo/', startmessaging)

    }
    

sendmessage(convoid)
{
  var message ={
    Conversation_id :convoid,
    Message_text: this.Messagemodel.value.MessageText   

  }
  return this.http.post(this.BaseURI+'conversations/newmessage/', message)
}


checknewmessages(convoid,mesid)
{
   var packcheck = 
  {
    Conversation_id : convoid,
    Message_id: mesid
   // Message_text : mescount
  }

  return this.http.post(this.BaseURI+'conversations/checknewmessage/', packcheck)
}


checkmescount(user_count,convoid)
{
  var count ={Message_count: user_count
  ,Conversation_id : convoid
  }
  return this.http.post(this.BaseURI+'conversations/checkmescount/', count,  {responseType: 'text'})
}

getmesscount(cid)
{
  var convoid={Conversation_id:cid}
  return this.http.post(this.BaseURI+'conversations/getmesscount/', convoid)

}



getmoremessages(convoid,oldestmessdisplay,tbl_gen)
{
  var getmore = {
    Conversation_id: convoid,
    Message_id : oldestmessdisplay,
    Tbl_gen : tbl_gen
  }
    
    return this.http.post(this.BaseURI+'conversations/getmoremessages/', getmore)
}

getmoreconversations(olderconvoid,convocnt)
{
  var convovombo = {Conversation_id:olderconvoid
    ,Message_count: convocnt
  }
  return this.http.post(this.BaseURI+'conversations/getmoreconvos/', convovombo)
}
  


removefromhub(convoid)
{
  var pack ={Conversation_id: convoid }

  return this.http.post(this.BaseURI+'conversations/removefromhub/', pack,  {responseType: 'text'})
}


exitanyhubs()
{
  var pack ={Hub_id: -2 }
  return this.http.post(this.BaseURI+'conversations/exitanyhubs/', pack,  {responseType: 'text'})
}

removeconvounread(convoid)
{
  var pack ={Conversation_id: convoid }
  return this.http.post(this.BaseURI+'conversations/sawunread/', pack,  {responseType: 'text'})
}

getchatapprovestatus(other_his_id)
{
  var pack ={Requesting_contact: other_his_id }
  return this.http.post(this.BaseURI+'conversations/getchatapprovestatus/', pack,  {responseType: 'text'})

}

fe_chat_permission_respones(x,other_id,st_id)
{
  var pack ={Requesting_contact: other_id 
  , Status : x
  ,Females_dating_profile_id:st_id
  }
  return this.http.post(this.BaseURI+'conversations/fe_chat_permission_respones/', pack,  {responseType: 'text'})
}



getinfoforfatherelder(user_name)
{
  var pack = {Userid:user_name}
    
  return this.http.post(this.BaseURI+'conversations/getinfoforfatherelder/', pack)
}
  

getifuserdtnchat_fe (userid)
{

  var pack ={Userid: userid 
    }
  return this.http.post(this.BaseURI+'conversations/getifuserdtnchat_fe/', pack,  {responseType: 'text'})
}




load_pre_load_convos ()
{

  var pack ={Conversation_id: 0
    }
  return this.http.post(this.BaseURI+'conversations/load_pre_load_convos/', pack,  {responseType: 'text'})
}




}
