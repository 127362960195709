import { Injectable } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { PlatformService } from './shared/platform.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private fb:FormBuilder, private http:HttpClient, private platforbaseur: PlatformService) { 
  } 
 // readonly BaseURI = 'http://localhost:44385/api/';
 readonly BaseURI =this.platforbaseur.BaseURI; 



 formModel_card = this.fb.group({
  Card_number :['', [Validators.required,Validators.min(15),Validators.max(19)]],
  Expiration :['',[Validators.required,Validators.min(4),Validators.max(4)]],
  CVV_number :['',[Validators.required,Validators.minLength(3),Validators.maxLength(4),]],
  First_name :['', [Validators.required,Validators.min(2),Validators.max(50)]],
  Last_name :['',[Validators.required,Validators.min(2),Validators.max(50)]],
  Zip_code :['',[Validators.required,Validators.minLength(5),Validators.maxLength(5)]],
  City :['',[Validators.required,Validators.minLength(4),Validators.maxLength(40)]],
  Street_address :['',[Validators.required,Validators.minLength(6),Validators.maxLength(150)]]
  
  });



check_subs()
  {
    var pack ={Status: ""}
   return this.http.post(this.BaseURI+'Products/check_sub/', pack,  {responseType: 'text'})
  }


  check_subs_onuserload(othersid)
  {
    var pack ={Userid: othersid}
   return this.http.post(this.BaseURI+'Products/check_subs_onuserload/', pack,  {responseType: 'text'})
  }


  check_other_gender_as_sub(other_db_id)
  {
    var pack ={User_db_id: other_db_id }
    
    return this.http.post(this.BaseURI+'Products/check_other_gender_as_sub/', pack,  {responseType: 'text'})
  }
  
  
  get_prices()
  {
    var pack = {List_type:-1}
    return this.http.post(this.BaseURI+'Products/get_prices/', pack)
  }


  check_if_cart()
  {
    var pack = {User_db_id:""}
    return this.http.post(this.BaseURI+'Products/getcart/', pack)
  }

  add_cart_item(product)
  {
    var pack = {ItemOrder:product}
    return this.http.post(this.BaseURI+'Products/add_cart_item/', pack)
  }

  remove_cart_item(product)
  {
    var pack = {ItemOrder:product}
    return this.http.post(this.BaseURI+'Products/remove_cart_item/', pack,  {responseType: 'text'})
  }

  
  create_paypal_order(cart_id)
  {
    var order = {Cart_id:cart_id}
    return this.http.post(this.BaseURI+'Products/create_paypal_order/', order,  {responseType: 'text'})
  }

  capture_paypal_order()
  {
    var order = {Cart_id:-1}
    return this.http.post(this.BaseURI+'Products/capture_paypal_order/', order,  {responseType: 'text'})
  }


  create_complemate_order_v1(cart_id)
  {
    var order = {Cart_id:cart_id}
    return this.http.post(this.BaseURI+'Products/create_complemate_order_v1/', order,  {responseType: 'text'})
  }


  add_state(st)
  {
    var order = {State:st}
    return this.http.post(this.BaseURI+'Products/add_state/', order,  {responseType: 'text'})
  }



  get_total_tax()
  {
    var order = {Payment_id:0}
    return this.http.post(this.BaseURI+'Products/get_total_tax/', order)
  }

get_state()
{
  var order = {State:""}
  return this.http.post(this.BaseURI+'Products/get_state/', order,  {responseType: 'text'})
}

update_order_status(flag)
{
  var order = {User_db_id:flag}
  return this.http.post(this.BaseURI+'Products/update_order_status/', order,  {responseType: 'text'})
}


sqaure_payments_v1()
{
  var order = {User_db_id:"flag"}

return this.http.post(this.BaseURI+'Products/sqaure_payments_v1/', order,  {responseType: 'text'}) 

}


check_payment()
{
  var order = {User_db_id:"flag"}

return this.http.post(this.BaseURI+'Products/check_payment_v1/', order,  {responseType: 'text'}) 
}

}
 