import { Component, OnInit } from '@angular/core';
import { ConnectionsService } from '../connections.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  list_of_notes;
  clear_flag = 0;
  offset = new Date().getTimezoneOffset();
  person_icon = 'src/app/images/person_icon.png'; 

  constructor(private connectservice : ConnectionsService,private router:Router) { }

  ngOnInit() {
this.connectservice.get_notes().subscribe(
  res => {
this.list_of_notes =res;

if(this.list_of_notes.lengh != 0)
{this.clear_flag =1;}


var idx_here = 0;
for (var n in this.list_of_notes)
{
 
  var postdate = new Date(this.list_of_notes[idx_here]['last_updated']);
  var offsettime = new Date(postdate.getTime() + this.offset * -60 *1000 ) 
  this.list_of_notes[idx_here]['last_updated']= offsettime;

if(this.list_of_notes[idx_here]['profile_image'] == "" || this.list_of_notes[idx_here]['profile_image'] == null)
{this.list_of_notes[idx_here]['profile_image'] = this.person_icon;}
  
  idx_here = idx_here + 1;

}


  },
  err => {console.log("Issue getting notes")},
)

  }///////////////////////////////// Last bracket of ngoninit

  redirect(uri:string)
  {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate([uri]));
  }


  gotoobject(type,objectid,groupflag,tbl_gen)
  {


    this.removeset(type,objectid,groupflag,0); 

    var userid;
    var postid;
    var grpid;
    
    //this function to get post number and or grpid/ userid
    this.connectservice.get_note_post(type,objectid,groupflag,tbl_gen).subscribe(
      res => {
        var objectpack = res;

        if(groupflag != 'Y')
        {
        
          userid = objectpack[0]["user_profile_id"];
          postid = objectpack[0]["postid"];
          

        }
        else{
          grpid = objectpack[0]["grp_id"];
          postid = objectpack[0]["post_id"];
        }
        
        if(groupflag != 'Y')
        { 
          //this.redirect(userid +"/" + postid + "/" + tbl_gen); 
          this.router.navigateByUrl(userid +"/" + postid + "/" + tbl_gen );
      }
        else if(groupflag == 'Y')
        {
          //this.redirect("group/" + grpid + "/" + postid + "/" + tbl_gen);
          this.router.navigateByUrl("group/" + grpid + "/" + postid + "/" + tbl_gen );
        }
    


      },
      err => {console.log("Error getting object IDs")}
    )


  }



removeset(type,objectid,groupflag,many)
{
  this.connectservice.remove_sets(type,objectid,groupflag,many).subscribe(
    res => {
      console.log("Cool");
    },
    err => {console.log("Not Cool");},

  )
}


clear_notes()
{
this.removeset(0,0,"X",1);
this.list_of_notes= null;
this.clear_flag =0;
}

see_all()
{
 
  this.router.navigateByUrl("/notification_history"); 
}


}
