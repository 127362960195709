import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PlatformService } from '../shared/platform.service';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { UserService } from './../shared/user.service';
import { ConnectionsService } from '../connections.service';
import { ControlBar } from '../control-bar/control-bar.component';
import { ChatService } from '../chat.service';


@Component({
  selector: 'app-hamburger',
  templateUrl: './hamburger.component.html',
  styleUrls: ['./hamburger.component.css']
})
export class HamburgerComponent implements OnInit {



userdetails;
ipaddress;
ismobile="";
singletownflag ='M';  
person_icon = 'src/app/images/person_icon.png';
prelaunch ='Y'
us_or_no ='N';

  constructor(private router:Router, private getstanderprofile : PlatformService, private http:HttpClient,private service:UserService,private connectservice : ConnectionsService,private chatservice : ChatService) { }

  ngOnInit() {

    this.service.app_launch_check().subscribe(
      res => {
        this.prelaunch = res;
      },
      err => {},
    
    )
    

this. check_if_us();
   //Get user information

   this.getstanderprofile.getuserinfomenu().subscribe(
     res =>{
       this.userdetails = res;
     

       if(this.userdetails[0]['profileimage'] ==  null || this.userdetails[0]['profileimage'] ==  "")
       {
         this.userdetails[0]['profileimage'] = this.person_icon;
       }
     },
     err =>{},
   )

   this.connectservice.checksingletown("blah").subscribe(
    res => {
  
      this.singletownflag = res;
    },
    err => {},
  )
  

 

  }///////////////////////////////////// Last ngoninit

  



  gotoprofile(userid) 
  {  
     this.router.navigateByUrl(userid +'/profile');
    //this.profile_redirect(userid +'/profile');

  }
profile_redirect(uri:string)
{
  this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
  this.router.navigate([uri]));
}



gotoeditprofile()
 {
  this.router.navigateByUrl('/editprofile');
 }

 gotogroups()
 {
  this.router.navigateByUrl('/groups');
 }


 gotofriends()
 {
  this.router.navigateByUrl('/friends');

 }

 gotofindfriends()
 { this.router.navigateByUrl('/findfriends');}

 gotofindgroups()
 { this.router.navigateByUrl('/findgroups');}

 gotomydatingprofile()
 {this.router.navigateByUrl('/editdatingprofile');}

 gotofindcomplemate() 
 {


    if(this.singletownflag == "A")
    {
      this.router.navigateByUrl("/findamate" );
    }
    else if(this.singletownflag == "S")
    {
     this.router.navigateByUrl('/datingfinalsteps'); 
    }
    else
    {
     this.router.navigateByUrl('/datingsetup');
    }

 }



  onLogout(){
    //this.getIPAddress();

   this.exithubs();
    this.sendloginfo();
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
  }
  


  sendloginfo()
  {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      this.ismobile="Y";
    }
    else if(!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
    {
      this.ismobile="N";
    }
    else{this.ismobile="E";}

    const { detect } = require('detect-browser');
    const browser = detect();

    
  



    this.service.sendlogoutinfo(this.ipaddress,browser.name,browser.version,browser.os,this.ismobile).subscribe(
      res => {
        console.log(res);
      },
      err => {
        console.log(err);
      },
    )


  }

  getIPAddress()
  {
   
    this.http.get("https://api.ipify.org/?format=json").subscribe((res:any)=>{
      this.ipaddress = res.ip;
      console.log(this.ipaddress);
    });
  }



  exithubs()
  {
    
this.chatservice.exitanyhubs().subscribe( 
  res => {
    //console.log("Removed from hubs");
},
  err => {console.log("Error removing from hub");},
)
  }

  gotopostboard()
  {

    this.router.navigateByUrl("/postboard" );
  }

  gotooptions()
  {

    this.router.navigateByUrl("/options" );
  }


  check_if_us()
    {
     var tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
     
   if (tz == 'America/Chicago'
   || tz == 'America/Denver'
   || tz == 'America/Phoenix'
   || tz == 'America/Los_Angeles'
   || tz == 'America/Anchorage'
   || tz == 'Pacific/Honolulu'
   )
   {this.us_or_no = 'Y'}
   else{this.us_or_no = 'N'}
   
   
    }


}
