import { Injectable } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { createTokenForExternalReference } from '@angular/compiler/src/identifiers';
import { PlatformService } from './platform.service';

@Injectable({
  providedIn: 'root'
})

export class UserService {

  constructor(private fb:FormBuilder, private http:HttpClient, private platforbaseur: PlatformService ) { }
 // readonly BaseURI = 'https://localhost:44385/api/';
  //readonly BaseURI = 'http://localhost:44385/api/'; 
  //readonly BaseURI = 'http://complemate.com:5000/api/';


  readonly BaseURI =this.platforbaseur.BaseURI; 
  

formModel = this.fb.group({
First_name :['', [Validators.required,Validators.min(2),Validators.max(50)]],
Last_name :['',[Validators.required,Validators.min(2),Validators.max(50)]],
Password :['',[Validators.required,Validators.minLength(6),Validators.maxLength(26)]],
Emails: this.fb.group({
  Email :['',[Validators.required,Validators.email,Validators.max(60)]],
  ComfirmEmail :['',[Validators.required]] 
},{ validator : this.compareEmails}),

Gender: ['',[Validators.required]],
DOB: ['',[Validators.required]],
MaritalStatus: ['',[Validators.required]]

});


subscription_up = this.fb.group({
  Email :['', [Validators.required,Validators.min(2),Validators.max(60)]],
  Gender: ['',[Validators.required]],
  DOB: ['',[Validators.required]],
  MaritalStatus: ['',[Validators.required]]   
});




compareEmails(fb:FormGroup){
  let  confirmEmailCtrl = fb.get('ComfirmEmail')
if(confirmEmailCtrl.errors ==null || 'emailMisMatch' in confirmEmailCtrl.errors){
  if(fb.get('Email').value!= confirmEmailCtrl.value )
  confirmEmailCtrl.setErrors({emailMisMatch:true});
  else
  confirmEmailCtrl.setErrors(null);

}
}

register(){ 

   
  this.formModel.value.First_name = this.formModel.value.First_name.replace(/ /g, "");
  this.formModel.value.Last_name = this.formModel.value.Last_name.replace(/ /g, "");

  this.formModel.value.Last_name = this.formModel.value.Last_name.replace(/[^a-zA-Z ]/g, "");
  this.formModel.value.First_name = this.formModel.value.First_name.replace(/[^a-zA-Z ]/g, ""); 
  
  var body = {
First_name : this.formModel.value.First_name,
Last_name : this.formModel.value.Last_name,
PasswordHash : this.formModel.value.Password,
Email : this.formModel.value.Emails.Email,
User_type:"Standard",
UserName: this.formModel.value.Emails.Email,
Dob: this.formModel.value.DOB,
Gender: this.formModel.value.Gender,
RegistrationDate: new Date(),
Timezone_real:Intl.DateTimeFormat().resolvedOptions().timeZone,
Id:this.formModel.value.MaritalStatus

  };

  // var isfirstnameemt = this.formModel.value.First_name ;
//  var islastnaememt = this.formModel.value.Last_name ;


return this.http.post(this.BaseURI+'ApplicationUser/Register', body);

}

sub_up_submit()
{
  var body = {
    Email : this.subscription_up.value.Email,
    Dob : this.subscription_up.value.DOB,
    Marital_status : this.subscription_up.value.MaritalStatus,
    Gender : this.subscription_up.value.Gender,
    
      };
  

  return this.http.post(this.BaseURI+'ApplicationUser/sub_uping', body, {responseType: 'text'});
}


login(formData)
{ 
 
  return this.http.post(this.BaseURI+'ApplicationUser/Login', formData);
}


sendloginfo(IPA,Browser,Browser_version,os,mobile)
{
var log={
  Ip_address:IPA,
  Browser:Browser,
  Browser_version:Browser_version,
  Os: os,
  Mobile:mobile

}

  return this.http.post(this.BaseURI+'ApplicationUser/loginfo', log);
}

sendlogoutinfo(IPA,Browser,Browser_version,os,mobile)
{
var log={
  Ip_address:IPA,
  Browser:Browser,
  Browser_version:Browser_version,
  Os: os,
  Mobile:mobile

}

  return this.http.post(this.BaseURI+'ApplicationUser/logout', log);
}


getUserProfile(){
 
  return this.http.get(this.BaseURI+'UserProfile');   
}



roleMatch(allowedRoles): boolean{
  var isMatch = false;
  var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
  var userRole = payLoad.role;
  allowedRoles.forEach(element => {
    if(userRole == element){
      isMatch =true;
      return false;
    }
  });
  
    return isMatch;
  }
  



anyuserprofile(){
return this.http.get(this.BaseURI+'UserProfile');  

var useridlit;

}






resetkey(email)
{
var pack={
  Email:email,
}

  return this.http.post(this.BaseURI+'ApplicationUser/keyreset', pack, {responseType: 'text'});
}

updatekey(email,token,newkey)
{
  var pack={
    Email:email,
    PasswordHash:newkey,
    SecurityStamp:token

  }

  return this.http.post(this.BaseURI+'ApplicationUser/updatekey', pack, {responseType: 'text'});

}

pwmodel = this.fb.group({
  
    Password :['', [Validators.required,Validators.minLength(6),Validators.max(15)]],
    Comfirmpassword :['',[Validators.required,Validators.minLength(6),Validators.max(15)]],
 
  });
  
 



  app_launch_check()
{
var pack={
  List_type:7,
}

  return this.http.post(this.BaseURI+'ApplicationUser/app_launch_check', pack, {responseType: 'text'});
}


send_hit_data(IP, Brs, brsv,OS,mob,tz,pr,udt,dts)
{

  var pack={
    Ip_address:IP,
    Browser:Brs,
    Browser_version:brsv,
    Os:OS,
    Mobile:mob,
    Timezone:tz,
    Path_route:pr,
    Userdatetime:udt,
    Userdatetimestring:dts
  }

  return this.http.post(this.BaseURI+'ApplicationUser/Hit_stats', pack, {responseType: 'text'});
}


}




