import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConnectionsService } from '../../connections.service';
import { UserService } from '../../shared/user.service';

@Component({
  selector: 'app-smallhomemenubottom',
  templateUrl: './smallhomemenubottom.component.html',
  styleUrls: ['./../homebase.component.css']
})
export class SmallhomemenubottomComponent implements OnInit {

  singletownflag;
  prelaunch ='Y'
  us_or_no ='N';
  alert_box_show = 'N';
alert_box_text="";

  constructor(private router:Router,private connectservice : ConnectionsService, private service:UserService) { }

  ngOnInit() {

    this.service.app_launch_check().subscribe(
      res => {
        this.prelaunch = res;
      },
      err => {},
    
    )
    
    this.check_if_us();
    

    this.connectservice.checksingletown("blah").subscribe( 
      res => {
    
        this.singletownflag = res;
    
      },
      err => {},
    )


  }/////////////////////////////// Last ngoninit braket
  
  gosearch_redirect(uri:string) 
  {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate([uri]));
  }


gohome()
{
  this.router.navigate(['/home']);
}


gotogroups()
{
 this.router.navigateByUrl('/groups');
}

gotopostboard()
{this.router.navigateByUrl('/postboard');}


gotodating() 
{

  
    //figure out if user has a profile or is in the middle of dating registration to determine which componet/page to send them to

    if(this.singletownflag == "A")
    {
      if(this.prelaunch == 'Y' )
      {
        this.alert_box_text = "You have already signed up. Please come back after the Complemate launch.";
        this.alert_box_show = 'Y';
      }
      else{
       this.gosearch_redirect("findamate/" );
      }

    }
    else if(this.singletownflag == "S")
    {
     this.router.navigateByUrl('/datingfinalsteps'); 
    }
    else
    {
    this.router.navigateByUrl('/datingsetup');
    }
  //figure out if user has a profile or is in the middle of dating registration to determine which componet/page to send them to
/*
  if(this.singletownflag == "A") 
  {
    this.gosearch_redirect("findamate/" );   
  }
  else if(this.singletownflag == "S")
  {
   this.router.navigateByUrl('/datingfinalsteps'); 
  }
  else
  {
   this.router.navigateByUrl('/datingsetup');
  }
*/
}

check_if_us()
    {
     var tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
     
   if (tz == 'America/Chicago'
   || tz == 'America/Denver'
   || tz == 'America/Phoenix'
   || tz == 'America/Los_Angeles'
   || tz == 'America/Anchorage'
   || tz == 'Pacific/Honolulu'
   )
   {this.us_or_no = 'Y'}
   else{this.us_or_no = 'N'}
   
   
    }
    ok_alert()
    {this.alert_box_show = 'N'
    this.alert_box_text = "";
  }

}
