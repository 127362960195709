import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { UserRegistrationComponent } from './user-registration/user-registration.component'
import { Homebase } from './homebase/homebase.component';
import { RegistrationComponent } from './user-registration/registration/registration.component';
import {LoginComponent} from './user-registration/login/login.component';
import { AuthGuard } from './auth/auth.guard';
import { SearchComponent } from './search/search.component';
import { HamburgerComponent } from './hamburger/hamburger.component';
import { SubscriptionComponent } from './subscription/subscription.component';

 


const routes: Routes = [
 
//{path:'',redirectTo:'/user/login',pathMatch:'full'},


//The paths just below may not be used
  {
    path: 'user', component: UserRegistrationComponent,
    children: [
      { path: 'registration', component: RegistrationComponent },
       { path: 'login', component: LoginComponent }
    ]
  }


  
 ,{path: 'home', component:Homebase, canActivate:[AuthGuard]}
//,{path:"home", loadChildren:'../app/homebase/homebase.module#HomebaseModule'}
 //,{path: 'forbidden', component:ForbiddenComponent}                       (May use this at somepoint)
 //,{path: 'adminpanel', component:AdminPanelComponent, canActivate:[AuthGuard],data :{permittedRoles:['Admin']}}             (May use this at somepoint)



//Menus
,{path: 'account', component:HamburgerComponent, canActivate:[AuthGuard]}
//////////,{path: 'friends', component:FriendsComponent, canActivate:[AuthGuard]}
,{path: 'friends', loadChildren:'../app/friends/friends.module#FriendsModule', canActivate:[AuthGuard]}

   /////////// ,{path: 'groups', component:GroupsComponent, canActivate:[AuthGuard]}
   ,{path: 'groups', loadChildren:'../app/groups/groups.module#GroupsModule', canActivate:[AuthGuard]}
//,{path: 'groups',
 //component:GroupsComponent,
 //loadChildren:() => import('./groups/groups.component').then((x) =>x.GroupsComponent),
 //canActivate:[AuthGuard]}

 //,{path: 'groups', loadChildren: () => import('./groups/groups.module').then(m => m.GroupsModule) }

////////,{path: 'searchcomplemate', component:SearchcomplemateComponent, canActivate:[AuthGuard]}
,{path: 'searchcomplemate', loadChildren:'../app/searchcomplemate/searchcomplemate.module#SearchcomplemateModule', canActivate:[AuthGuard]}
//////////,{path: 'searchcomplemate/:searchquery', component:SearchcomplemateComponent, canActivate:[AuthGuard]}
,{path: 'searchcomplemate/:searchquery', loadChildren:'../app/searchcomplemate/searchcomplemate.module#SearchcomplemateModule', canActivate:[AuthGuard]}

//////////,{path: 'findfriends', component:FindFriendsComponent, canActivate:[AuthGuard]}
,{path: 'findfriends', loadChildren:'../app/find-friends/find-friends.module#FindFriendsModule', canActivate:[AuthGuard]}
/////////////,{path: 'findgroups', component:FindGroupsComponent, canActivate:[AuthGuard]}
,{path: 'findgroups', loadChildren:'../app/find-groups/find-groups.module#FindGroupsModule', canActivate:[AuthGuard]}
///////////,{path: 'findfriends/:searchquery', component:FindFriendsComponent, canActivate:[AuthGuard]}
,{path: 'findfriends/:searchquery', loadChildren:'../app/find-friends/find-friends.module#FindFriendsModule', canActivate:[AuthGuard]}
//,{path: 'findgroups/:searchquery', component:FindGroupsComponent, canActivate:[AuthGuard]}
,{path: 'findgroups/:searchquery', loadChildren:'../app/find-groups/find-groups.module#FindGroupsModule', canActivate:[AuthGuard]}
///////////,{path: 'options', component:OptionsComponent, canActivate:[AuthGuard]}

,{path: 'options', loadChildren:'../app/options/options.module#OptionsModule', canActivate:[AuthGuard]}
//,{path:"home", loadChildren:'../app/homebase/homebase.module#HomebaseModule'}   // <<< this is just a note that should be deleteed, a copy fr,om above

,{path: 'smith', loadChildren:'../app/smith/smith.module#SmithModule', canActivate:[AuthGuard]}

//dm
///,{path: 'messages', component:DirectmessagesComponent, canActivate:[AuthGuard]}
///,{path: 'messages/:userid', component:DirectmessagesComponent, canActivate:[AuthGuard]}
,{path: 'messages', loadChildren:'../app/directmessages/directmessages.module#DirectmessagesModule', canActivate:[AuthGuard]}
,{path: 'messages/:userid',  loadChildren:'../app/directmessages/directmessages.module#DirectmessagesModule', canActivate:[AuthGuard]}

//notification

///////,{path: 'notification_history', component:NotificationsAllComponent, canActivate:[AuthGuard]}
,{path: 'notification_history', loadChildren:'../app/notifications-all/notifications-all.module#NotificationsAllModule', canActivate:[AuthGuard]}
///////////////,{path: 'form', component:LogintaskComponent, canActivate:[AuthGuard]}
,{path: 'form', loadChildren:'../app/logintask/logintask.module#LogintaskModule', canActivate:[AuthGuard]}
////////,{path: 'emailverification', component:EmailVerificationComponent, canActivate:[AuthGuard]}
,{path: 'emailverification', loadChildren:'../app/email-verification/email-verification.module#EmailVerificationModule', canActivate:[AuthGuard]}
///////,{path: 'passwordreset', component:PasswordresetComponent}
,{path: 'passwordreset', loadChildren:'../app/passwordreset/passwordreset.module#PasswordresetModule'}


//For dating
/////,{path: 'datingsetup', component:DatingsetupComponent, canActivate:[AuthGuard]}
,{path: 'datingsetup', loadChildren:'../app/datingsetup/datingsetup.module#DatingsetupModule', canActivate:[AuthGuard]}
//,{path: 'datingfinalsteps', component:DatingfinalstepsComponent, canActivate:[AuthGuard]}
,{path: 'datingfinalsteps', loadChildren:'../app/datingfinalsteps/datingfinalsteps.module#DatingfinalstepsModule', canActivate:[AuthGuard]}
////,{path: 'stprofile/:did/:stuser', component:DatingprofileComponent, canActivate:[AuthGuard]} 
,{path: 'stprofile/:did/:stuser', loadChildren:'../app/datingprofile/datingprofile.module#DatingprofileModule', canActivate:[AuthGuard]} 
,{path: 'findamate', component:SearchComponent, canActivate:[AuthGuard]}
//////,{path: 'findamate', loadChildren:'../app/search/search.module#SearchModule', canActivate:[AuthGuard]}
/////,{path: 'editdatingprofile', component:DatingprofileeditComponent, canActivate:[AuthGuard]}
,{path: 'editdatingprofile', loadChildren:'../app/datingprofileedit/datingprofileedit.module#DatingprofileeditModule', canActivate:[AuthGuard]}

//products
,{path: 'join', component:SubscriptionComponent, canActivate:[AuthGuard]}
///////,{path: 'join', loadChildren:'../app/subscription/subscription.module#SubscriptionModule', canActivate:[AuthGuard]}

,{path: 'checkout', loadChildren:'../app/check-out/check-out.module#CheckOutModule', canActivate:[AuthGuard]}

// Preview pages
//////,{path: 'preview/:useridcon', component:ProfilepreviewComponent, canActivate:[AuthGuard]}
,{path: 'preview/:useridcon', loadChildren:'../app/profilepreview/profilepreview.module#ProfilepreviewModule', canActivate:[AuthGuard]}

///////,{path: 'grouppreview/:grpidcon', component:GrouppreviewComponent, canActivate:[AuthGuard]}
,{path: 'grouppreview/:grpidcon', loadChildren:'../app/grouppreview/grouppreview.module#GrouppreviewModule', canActivate:[AuthGuard]}



//Edits
//////,{path: 'editprofile', component:EditprofileComponent, canActivate:[AuthGuard]}
,{path: 'editprofile', loadChildren:'../app/editprofile/editprofile.module#EditprofileModule', canActivate:[AuthGuard]}
//////////,{path: 'editgroup/:gid', component:EditgroupComponent, canActivate:[AuthGuard]}
,{path: 'editgroup/:gid', loadChildren:'../app/editgroup/editgroup.module#EditgroupModule', canActivate:[AuthGuard]}
//////////,{path: 'cancelgroup/:gid', component:GroupcancelComponent, canActivate:[AuthGuard]}
,{path: 'cancelgroup/:gid', loadChildren:'../app/groupcancel/groupcancel.module#GroupcancelModule', canActivate:[AuthGuard]}



 
 //PATHS FOR POST BOARD
//////////,{path: 'postboard', component: PostboardComponent}
,{path: 'postboard', loadChildren:'../app/postboard/postboard.module#PostboardModule'}

// PATH FOR GROUP REGISTRATION
//////////////////,{path: 'groupregistration', component:GroupregistrationComponent,canActivate:[AuthGuard]}
,{path: 'groupregistration',loadChildren:'../app/groupregistration/groupregistration.module#GroupregistrationModule',canActivate:[AuthGuard]}
/////////,{path: ':grpid/groupmembers', component:GroupmembersComponent,canActivate:[AuthGuard]}
,{path: ':grpid/groupmembers', loadChildren:'../app/groupmembers/groupmembers.module#GroupmembersModule',canActivate:[AuthGuard]}



,{
  path: 'group/:id', 
 // children:[ { path: 'home', component: GrouphomeComponent },
 children:[ { path: 'home', loadChildren:'../app/grouphome/grouphome.module#GrouphomeModule' },
         //    { path: ':gpost/:tbl_gen', component: GrouppostComponent }
         { path: ':gpost/:tbl_gen', loadChildren:'../app/grouppost/grouppost.module#GrouppostModule' }
]
}



,{
  path: '', component: HomeComponent,
  children:[ { path: '', component: RegistrationComponent },
             { path: 'login', component: LoginComponent }
]
}



//PATHS FOR USER PROFILES
//,{path:'profile', component: UserprofileComponent}
,{ path: ':userid',
// children: [{ path: 'profile', component: UserprofileComponent, canActivate:[AuthGuard]}
children: [{ path: 'profile', loadChildren:'../app/userprofile/userprofile.module#UserprofileModule', canActivate:[AuthGuard]}
///// ,          { path: ':post/:tbl_gen', component: CommentComponent, canActivate:[AuthGuard]}
,          { path: ':post/:tbl_gen', loadChildren:'../app/comment/comment.module#CommentModule', canActivate:[AuthGuard]}
]}




//        things after this point was not working for some reason

//PATHS FOR COMMENTS





  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
export const rountingComponents = [UserRegistrationComponent]

