import { Injectable } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { Binary } from '@angular/compiler';
import { PlatformService } from './shared/platform.service';


@Injectable({
  providedIn: 'root'
})
export class ConnectionsService {

  constructor(private fb:FormBuilder, private http:HttpClient, private platforbaseur: PlatformService) { }

 // readonly BaseURI = 'http://localhost:44385/api/';
 readonly BaseURI =this.platforbaseur.BaseURI; 




  getprofilepreview(useridcon)
  {
    return this.http.post(this.BaseURI+'Connections/getprofilepreview/', useridcon)

  }


sendconrequest(Receiverid,Type)
{
var mail={
  Receiver :Receiverid,
  Type : Type
}

  return this.http.post(this.BaseURI+'Connections/sendrequest/', mail,{responseType: 'text'})

}



getrequest()
{

 var reqq={
  Type :1
  }
  return this.http.post(this.BaseURI+'Connections/getrequest/', reqq)
}



getgrouprequest()
{


 var reqq={"Type" :"2" }
  return this.http.post(this.BaseURI+'Connections/getgrprequest/', reqq)
  
}


ferequest(req,selection)
{
 

 var fe_request_response={Requestor :req 
,Brokenby : selection
}


  return this.http.post(this.BaseURI+'Connections/ferequest/', fe_request_response, {responseType: 'text'}) 
  
}




giveresponse(selected,requestor,type,grpid)
{
  var selection ={
    Status:selected 
  ,Requestor:requestor
  ,Type:type
  ,Groupreceiver:grpid
  }

  return this.http.post(this.BaseURI+'Connections/giverespose/', selection)
}



ssuserprofile(userid)
{



  var connectioncheck={
    Userid :userid
  }
  return this.http.post(this.BaseURI+'Connections/ssuserprofile/', connectioncheck, {responseType: 'text'})
}

sspostcomment(postid)
{

  return this.http.post(this.BaseURI+'Connections/sspostcomment/', postid, {responseType: 'text'})
}

ssgroupcomment(grp_id)
{
  return this.http.post(this.BaseURI+'Connections/ssgroupcomment/', grp_id, {responseType: 'text'})
}



/// Group methods
getgrouppreview(grpid)
{
  
  return this.http.post(this.BaseURI+'Connections/getgrouppreview/', grpid)
}


ssgroup(groupid)
{
  var gconnectioncheck={
    Grp_id :groupid
  }
  return this.http.post(this.BaseURI+'Connections/ssgroup/', gconnectioncheck, {responseType: 'text'})

}

getfriendrequest(stauts,skip)
{
  var constatus={
    Status :stauts,
    Groupreceiver :skip
  }


  return this.http.post(this.BaseURI+'Connections/getfriendrequest/', constatus) 
}

isfriend(profileid)
{
  var friendcheck={ Userid: profileid}
  return this.http.post(this.BaseURI+'Connections/isfriend/', friendcheck, {responseType: 'text'})
}

unfriend(user_to_unfriend)
{
  var thatuser={ Userid: user_to_unfriend}
  return this.http.post(this.BaseURI+'Connections/unfriend/', thatuser, {responseType: 'text'})
  
}


groupscheck(status,cnt)
{
  var checkstatus ={Status: status,Groupreceiver: cnt }
  return this.http.post(this.BaseURI+'Connections/groupscheck/', checkstatus)
}



getmygroups(cnt)
{
  var checkstatus ={Status: cnt}
  return this.http.post(this.BaseURI+'Connections/mygroups/', checkstatus)
}


leavegroup(grp_id)
{
  var thegroup={ Grp_id: grp_id}
  return this.http.post(this.BaseURI+'Connections/leavegroup/', thegroup, {responseType: 'text'})
  
}

SearchFormModel= this.fb.group({
  SearchText :['',[Validators.required]]
})

SearchFormModel_groups= this.fb.group({
  SearchText :['',[Validators.required]]
})


SearchFormModel_friends= this.fb.group({
  SearchText :['',[Validators.required]]
})



getresults(thequery)
{ var search={ First_name : thequery}
return this.http.post(this.BaseURI+'Connections/searchcomplemate/', search)
}


loadpeoplesearch(thequery, cnt)
{
  var search={ First_name : thequery,
    Trouble_meter:cnt
  }
  return this.http.post(this.BaseURI+'Connections/loadpeoplesearch/', search)

}


loadgroupsearch(thequery, gcnt)
{
  var search={ Grp_name : thequery,
    Report_count:gcnt
  }
  return this.http.post(this.BaseURI+'Connections/loadgroupsearch/', search)

}


check_group_status(grp_id)
{
  var check ={Groupreceiver:grp_id}
  return this.http.post(this.BaseURI+'Connections/check_group_status/', check ,{responseType: 'text'})

}


sscancelgroup(grp_id)
{
  var check ={Grp_id:grp_id}
  return this.http.post(this.BaseURI+'Connections/sscancelgroup/', check ,{responseType: 'text'})
}




checksingletown(userid)
{

  var userhceck={ First_name : userid}

  return this.http.post(this.BaseURI+'Connections/checksingletown/', userhceck, {responseType: 'text'}) 
}



getuserid(udbid)
{

  var getuserfromdbid={ User_db_id : udbid}

  return this.http.post(this.BaseURI+'Connections/getdbid/', getuserfromdbid, {responseType: 'text'})  
}

getdbid_fromusid(userid)
{

  var pack={ Userid : userid}

  return this.http.post(this.BaseURI+'Connections/getdbid_fromusid/', pack, {responseType: 'text'})  
}



checkrequeststatus(type,useridorgrpid)
{

  var checkpack ={
    Type:type,
    Requestor :useridorgrpid
  }

  return this.http.post(this.BaseURI+'Connections/checkrequeststatus/', checkpack, {responseType: 'text'}) 
}



controlbarinfo()
{
  var getbarinfo ={ Userid : "dummary"}

  return this.http.post(this.BaseURI+'UserProfile/controlbarinfo/', getbarinfo)

}


reset_note_number(typenumber)
{
  var type ={ Id : typenumber}

  return this.http.post(this.BaseURI+'UserProfile/reset_note_number/', type)

}


get_notes()
{
  var key ={ User_id : "dum"}

  return this.http.post(this.BaseURI+'Connections/get_notes/', key)
}

get_notes_hist(count)
{
  var key ={ ID : count}

  return this.http.post(this.BaseURI+'Connections/get_notes_hist/', key)
}

get_note_post(type,objectid,groupflag,tbl_gen)
{
  var order ={
     Type : type,
     Object_id : objectid,
     Group_flag : groupflag,
     Tbl_gen: tbl_gen
}

  return this.http.post(this.BaseURI+'Connections/get_note_post/', order)
}


remove_sets(type,objectid,groupflag,many)
{
  var order ={
    Type : type,
    Object_id : objectid,
    Group_flag : groupflag,
    Current_action_count: many
}

  return this.http.post(this.BaseURI+'Connections/remove_sets/', order, {responseType: 'text'})
}


getwhofatherelderto()
{ var note ={Connect_id : 0}
  return this.http.post(this.BaseURI+'Connections/getwhofatherelderto/', note)
}


getnewdtchat()
{
  var note ={Connect_id : 0}
  return this.http.post(this.BaseURI+'Connections/getnewdtchat/', note)
}

newfemalechatack(x)
{
  var note ={Connect_id : x}
  return this.http.post(this.BaseURI+'Connections/newfemalechatack/', note, {responseType: 'text'})
}


get_reg_date(userid)
{
  var note ={Userid : userid}
  return this.http.post(this.BaseURI+'Connections/get_reg_date/', note)
}

isgrpmember(grpid)
{ 
  var note ={Grp_id : grpid}
  return this.http.post(this.BaseURI+'Connections/isgrpmember/', note, {responseType: 'text'})
}


endconvo(convoid)
{
  var pack ={Conversation_id: convoid }
  return this.http.post(this.BaseURI+'conversations/endconvo/', pack,  {responseType: 'text'})

}


checkblock(notcurrentuser)
{
  var pack ={Who_was_blocked: notcurrentuser }
  return this.http.post(this.BaseURI+'conversations/checkblock/', pack,  {responseType: 'text'})

}


get_dating_link_info(userdbid)
{
  var note ={User_db_id : userdbid}
  return this.http.post(this.BaseURI+'Connections/get_dating_link_info/', note)
}






}
